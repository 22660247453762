import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash/debounce';
import SingleInputDateTimeRangePicker from '../../globalComponents/dateAndTime/SingleDateAndTimeRangePicker';
import {
  getWorkspaceDataforDataCorrection,
  getPlantDataforDataCorrection,
  getSensorDataforDataCorrection,
} from '../../services/DataCorrection/DataCorrection';
import { toast } from 'react-toastify';
import { Divider } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface Plant {
  _id: string;
  name: string;
}

interface Sensor {
  sensorId: string;
  sensorName: string;
  sensorTag: string;
}

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)',
      sm: 'repeat(2, 1fr)',
      md: 'repeat(4, 1fr)',
    },
    '@media (max-width: 554px)': {
      gridTemplateColumns: 'repeat(1, 1fr)', // Adjust for medium screens
    },
    gap: '16px',
    alignItems: 'center',
    padding: '16px 0 0 4px',
  },
  datePicker: {
    paddingTop: 0,
    overflow: 'initial',
    '@media (max-width: 667px)': {
      minWidth: '50px', // Apply only for screen widths less than 667px
    },
  },
};

interface FilterSectionProps {
  onExecute: (payload: any) => void;
  dataPayload?: any;
}

const FilterSection: React.FC<FilterSectionProps> = ({ onExecute, dataPayload }) => {
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [plantOptions, setPlantOptions] = useState<Plant[]>([]);
  const [sensorOptions, setSensorOptions] = useState<Sensor[]>([]);
  const [selectedPlant, setSelectedPlant] = useState<any>(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);
  const [dataPayloadAvail, setdataPayloadAvail] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState<any>(null);
  const [timeFrame, setTimeFrame] = useState<Dayjs | null>(dayjs());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchWorkspaceOptions = async () => {
      try {
        const workspaces = await getWorkspaceDataforDataCorrection();
        setWorkspaceOptions(workspaces || []);
      } catch (err) {
        setWorkspaceOptions([]);
        throw err;
      }
    };

    fetchWorkspaceOptions();
  }, []);

  const handleWorkspaceSelect = async (event: any, newValue: any) => {
    if (newValue) {
      try {
        const plants = await getPlantDataforDataCorrection(newValue._id);
        setPlantOptions(plants);
        setSensorOptions([]); // Reset sensors when workspace changes
        setSelectedPlant(null); // Reset selected plant
        setSelectedSensor(null); // Reset selected sensor
        setSelectedWorkspace(newValue);
      } catch (err) {
        throw err;
      }
    }
  };

  const handlePlantSelect = async (event: any, newValue: any) => {
    setSelectedPlant(newValue);
    if (newValue) {
      try {
        const sensors = await getSensorDataforDataCorrection(newValue._id, '');
        setSensorOptions(sensors);
        setSelectedSensor(null);
      } catch (err) {
        throw err;
      }
    }
  };

  const fetchSensors = async (query: string) => {
    if (!selectedPlant?._id) return;

    setLoading(true);
    try {
      const sensors = await getSensorDataforDataCorrection(selectedPlant._id, query);
      setSensorOptions(sensors || []);
    } catch (err) {
      toast('Error fetching sensors:');
      setSensorOptions([]);
    } finally {
      setLoading(false);
    }
  };

  // Debounced function for fetching sensors
  const handleSearch = debounce((value: string) => {
    fetchSensors(value);
  }, 500);

  const handleExecute = () => {
    const payload = {
      plantId: selectedPlant?._id,
      plantName: selectedPlant?.name,
      sensorId: selectedSensor?.sensorId,
      sensorName: selectedSensor?.sensorName,
      fromDate: timeFrame,
    };
    onExecute(payload);
  };
  useEffect(() => {
    if (selectedPlant && selectedSensor && timeFrame) {
      handleExecute();
    }
  }, [selectedPlant, selectedSensor, timeFrame]);

  useEffect(() => {
    if (!dataPayloadAvail && dataPayload && workspaceOptions?.length > 0) {
      const { plantId, plantName, sensorId, sensorName, fromDate, sensorTag } = dataPayload;

      const matchingWorkspace: any = workspaceOptions.find(
        (workspace: any) => workspace?.assetIds === plantId
      );

      if (matchingWorkspace) {
        setSelectedWorkspace({
          _id: matchingWorkspace?._id,
          name: matchingWorkspace?.name,
          assetIds: matchingWorkspace?.assetIds,
        });
      }

      if (plantId && plantName) {
        setSelectedPlant({ _id: plantId, name: plantName });
      }

      if (sensorId && sensorTag) {
        setSelectedSensor({
          sensorId: sensorId,
          sensorName: sensorName,
          sensorTag: sensorTag,
        });
      }
      setdataPayloadAvail(true);

      if (fromDate) {
        setTimeFrame(dayjs(fromDate));
      }
    }
  }, [workspaceOptions, dataPayload]);

  return (
    <Box sx={styles.container}>
      <Autocomplete
        options={workspaceOptions}
        getOptionLabel={(option: any) => option?.name || ''}
        onChange={handleWorkspaceSelect}
        value={selectedWorkspace}
        renderInput={params => (
          <TextField
            {...params}
            label="Workspace"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
        disableClearable
      />

      <Autocomplete
        options={plantOptions}
        getOptionLabel={(option: any) => option?.name || ''}
        onChange={(event, newValue) => handlePlantSelect(event, newValue)}
        value={selectedPlant}
        renderInput={params => (
          <TextField
            {...params}
            label="Plant Name"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
        disableClearable
      />

      <Autocomplete
        options={sensorOptions}
        loading={loading}
        getOptionLabel={(option: any) => (option?.sensorTag ? `${option?.sensorTag}` : '')}
        value={selectedSensor}
        filterOptions={options => options} // Disable client-side filtering
        onInputChange={(event, value) => handleSearch(value)} // Trigger search on input change
        onChange={(event, newValue: Sensor | null) => setSelectedSensor(newValue)} // Set selected sensor
        renderOption={(props, option: any) => (
          <>
            {option?.sensorTag && (
              <>
                <li {...props} style={{ cursor: 'pointer' }}>
                  <div>
                    {option?.sensorTag && <div>{option.sensorName}</div>}
                    <div
                      style={{
                        fontSize: '0.8em',
                        color: option?.sensorName ? 'gray' : 'black',
                      }}
                    >
                      {option.sensorTag}
                    </div>
                  </div>
                </li>
                <Divider style={{ margin: '0.2em 0' }} />
              </>
            )}
          </>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label="Sensor Name/Tag"
            InputProps={{
              ...params.InputProps,
              type: 'search',
              endAdornment: (
                <div style={{ marginRight: '18px' }}>{params.InputProps.endAdornment}</div>
              ),
              style: {
                padding: '0.5em',
                borderRadius: '4px',
                width: '100%',
              },
            }}
            InputLabelProps={{
              style: {
                cursor: 'pointer', // Ensure label looks clickable
              },
            }}
          />
        )}
        disableClearable
        style={{
          width: '100%',
        }}
      />

      {/* Date Range Picker */}
      <Box sx={styles.datePicker}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={['DateTimePicker']}
            sx={{ paddingTop: 0, overflow: 'initial' }}
          >
            <DateTimePicker
              value={timeFrame}
              label="Date & Time (1-hour coverage)"
              onChange={(newTimeFrame: any) => setTimeFrame(newTimeFrame)}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default FilterSection;

import moment from "moment";

export default function formatDate(date: Date, format: String) {
  const map = {
    MM: date.getMonth() + 1,
    DD: date.getDate(),
    YYYY: date.getFullYear(),
    hh: date.getHours(),
    mm: date.getMinutes(),
    ss: date.getSeconds(),
  };

  return format.replace(/MM|DD|YYYY|hh|mm|ss/g, (matched) =>
    map[matched as keyof typeof map].toString()
  );
}


export const formatDateTime = (date: Date) => {
    const m = moment(date).utcOffset("+05:30");
    return m.format('MMM DD  hh:mm A');
  };
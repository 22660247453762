export const api_Url = 'http://localhost:5000/';
import AlertConfigIcon from '../assets/icons/ConfigIcons/Alert_Config.svg';
import DataInputConfigIcon from '../assets/icons/ConfigIcons/Data_Input_Config.svg';
import DosingConfigIcon from '../assets/icons/ConfigIcons/Dosing_Config.svg';
import FixedTasksConfigIcon from '../assets/icons/ConfigIcons/Fixed_Tasks_Config.svg';
import EscalationConfigIcon from '../assets/icons/ConfigIcons/Escalation_Config.svg';
import FormulaConfigIcon from '../assets/icons/ConfigIcons/Formula_Config.svg';
import ManualAlertConfigIcon from '../assets/icons/ConfigIcons/Manual_Alert_Config.svg';
import OneTimeTasksConfigIcon from '../assets/icons/ConfigIcons/One_Time_Tasks_Config.svg';
import PlantConfigIcon from '../assets/icons/ConfigIcons/Plant_Config.svg';
import dayjs from 'dayjs';
// import ManualEntryWidget from '../components/ManualEntryWidget/ManualEntryWidget';

const SENSOR_CATEGORIES = ['Real Sensors', 'Virtual Sensors', 'Data Input Sensors'];

const PLANT_CONFIG_DATA: any = {
  Tanks: {
    'Equalization Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Water Quality Analyser',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Aeration Tank': {
      sensors: [
        'SV30 Sensor',
        'SV30 Turbidity',
        'pH Sensor',
        'DO Sensor',
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'MBR Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'Inlet Pressure Transmitter',
        'Outlet Pressure Transmitter',
        'Pressure Switch Inlet',
        'Pressure Switch Outlet',
        'SV30 Sensor',
        'DO Sensor',
        'Turbidity Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'SBR Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'DO Sensor',
        'SV30 Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Sludge Holding Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Treated Water Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'Turbidity Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Chlorine Contact Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'ORP Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Soft Water Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'Turbidity Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Ultrafication Treated Water Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'Turbidity Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Anoxic Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'MBBR Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'SV30 Sensor',
        'DO Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Tube Settler Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Permeate Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Neutralization tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Coagulation Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Flocculation Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Caustic Dosing Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'PAC Dosing Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Poly Dosing Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Sodium Chloride Dosing Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Lime Dosing Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'UF CIP Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'UF permeate tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'RO permeate tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'MEE Condensate Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'MEE Concentrate Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
    'Custom Tank': {
      sensors: [
        'Level Transmitter',
        'High Level Switch',
        'Low Level Switch',
        'pH Sensor',
        'Turbidity Sensor',
        'BOD Water Quality Analyser',
        'COD Water Quality Analyser',
        'TSS Sensor',
        'Level Switch',
        'TDS',
        'ORP Sensor',
        'Pressure Transmitter Outlet Line',
        'Chlorine analyzer',
        'Conductivity Meter',
        'Conductivity Meter Switch',
      ],
    },
  },
  TankConfig: {
    material: ['RCC', 'MS', 'FRP', 'PVC/SDPE'],
  },
  Headers: {
    sensors: [
      'Pressure Transmitter',
      'Pressure Switch Inlet',
      'Pressure Switch Outlet',
      'Air Flowmeter',
    ],
  },
  Pumps: {
    sensors: [
      'ON/OFF Status',
      'Triping Status',
      'Manual/Auto Status',
      'Pressure Transmitter Outlet',
      'Ampere Sensor',
      'VFD Ampere',
      'VFD RPM',
      'VFD Frequency',
      'Temperature Sensor',
      'Pressure Switch Inlet',
      'Pressure Switch Outlet',
    ],
  },
  'Pump Types': [
    'Submersible - Cutter type',
    'Submersible - Non-cutter type',
    'Monobloc',
    'High Pressure - Vertical',
    'High Pressure - Horizontal',
    'Dosing pump',
    'Screw pump',
  ],
  Blowers: {
    sensors: [
      'ON/OFF Status',
      'Triping Status',
      'Manual/Auto Status',
      'Vibration Sensor',
      'Temperature Sensor',
      'VFD Ampere',
      'VFD RPM',
      'VFD Frequency',
    ],
  },
  Valves: {
    'Motorized Valve': {
      sensors: ['ON/OFF Status'],
    },
    'Solenoid Valve': {
      sensors: ['ON/OFF Status'],
    },
    'Actuator Valve': {
      sensors: ['ON/OFF Status', 'Triping Status', 'Manual/Auto Status'],
    },
  },
  'Other Sensors': [
    'Fingerprint Sensor',
    'Flooding Sensor',
    'Energy Meter',
    'Odour Sensor',
    'Smoke Detector',
    'Valves',
    'Chlorine Gas Analyzer',
    'Hooter',
    'Load Cell',
    'Floc Detector',
  ],
  'Core Equipments': {
    'Bar Screen Chamber': {
      sensors: [
        'Bar Screen Sensor',
        'Bar Screen Sensor 2',
        'Bar Screen Sensor 3',
        'Bar Screen Sensor 4',
        'Bar Screen Percentage Sensor',
        'Level Transmitter',
        'Ampere Sensor',
      ],
    },
    Decanter: {
      sensors: ['Proximity Sensor', 'ON/OFF Status', 'Ampere Sensor'],
    },
    'UF (Ultrafiltration)': {
      sensors: [
        'Pressure Transmitter Inlet',
        'Pressure Transmitter Outlet',
        'Pressure Transmitter Backwash',
        'Micron cartridge inlet pressure',
        'Flow switch in backwash line',
        'Ampere Sensor',
        'Bag Filter Pressure Transmitter',
        'Pressure Switch Inlet',
        'Pressure Switch Outlet',
      ],
    },
    Ozonator: {
      sensors: ['ORP Sensor', 'ON/OFF Status', 'Ampere Sensor'],
    },
    'Filter Press': {
      sensors: ['ON/OFF Status', 'Ampere Sensor'],
    },
    'Screw Press': {
      sensors: ['ON/OFF Status', 'Ampere Sensor'],
    },
    Centrifuge: {
      sensors: ['ON/OFF Status', 'Ampere Sensor'],
    },
    Softner: {
      sensors: [
        'Pressure Transmitter Outlet',
        'Flow switch in backwash line',
        'Ampere Sensor',
        'Pressure Switch Inlet',
        'Pressure Switch Outlet',
      ],
    },
    'Primary Clarifier': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    'Secondary Clarifier': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    Hydrocyclone: {
      sensors: [
        'Pressure Transmitter Inlet',
        'Pressure Transmitter Outlet',
        'Pressure Switch Inlet',
        'Pressure Switch Outlet',
        'Inlet Turbidity',
        'Inlet Chlorine',
        'Inlet TDS',
        'Inlet PH',
        'Ampere Sensor',
      ],
    },
    Compressor: {
      sensors: ['ON/OFF Status', 'Ampere Sensor'],
    },
    'Sludge thickener': {
      sensors: ['ON/OFF Status', 'Ampere Sensor'],
    },
    MGF: {
      sensors: [
        'Flow switch in backwash line',
        'Pressure Transmitter Inlet',
        'Pressure Transmitter Outlet',
        'Pressure Switch Inlet',
        'Pressure Switch Outlet',
        'Ampere Sensor',
      ],
    },
    ACF: {
      sensors: [
        'Flow switch in backwash line',
        'Pressure Transmitter Inlet',
        'Pressure Transmitter Outlet',
        'Pressure Switch Inlet',
        'Pressure Switch Outlet',
        'Ampere Sensor',
      ],
    },
    DMF: {
      sensors: [
        'Flow switch in backwash line',
        'Pressure Transmitter Inlet',
        'Pressure Transmitter Outlet',
        'Pressure Switch Inlet',
        'Pressure Switch Outlet',
        'Ampere Sensor',
      ],
    },
    'Anoxic Mixer': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    'Anaerobic Mixer': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    Agitator: {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    'RO Membrane': {
      sensors: [
        'ON/OFF Status',
        'Manual/Auto Status',
        'Triping Status',
        'Ampere Sensor',
        'Pressure Transmitter Inlet',
        'Pressure Transmitter Reject',
        'Pressure Transmitter Permeate',
        'Pressure Switch Inlet',
        'Pressure Switch Outlet',
      ],
    },
    'RO Micron Cartridge Filter': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    'Vapour Liquid Separator': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    'Forced Circulation Evaporator': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    'Pre-Heater': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    'Condensate Pot': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    'Thermo Compressor': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    'Custom Equipment': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
    'UV System': {
      sensors: ['ON/OFF Status', 'Manual/Auto Status', 'Triping Status', 'Ampere Sensor'],
    },
  },
};

const ASSIGN_ROLE = [
  'Operator',
  'Rotating Operator',
  'Operator Group',
  'Maintenance In-Charge',
  'Site Supervisor',
  'Service Head',
  'Procurer',
  'Building Level Client',
  'Senior Client',
];

const ISSUE_TYPES = [
  'Process',
  'Electrical',
  'Mechnical',
  'HouseKeeping',
  'Admin',
  'System',
  'Lab',
];

const PRIORITY_TYPES = ['Low', 'Medium', 'High'];

const ALERT_TYPES = ['Sensor', 'Data Input', 'Mixed'];

const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const WEEKS = ['1', '2', '3', '4', '5'];
interface RelativeTimeListInterface {
  [key: string]: number;
}
const RELATIVE_TIME_LIST: RelativeTimeListInterface = {
  '15 Mins': 15,
  '30 Mins': 30,
  '1 Hour': 60,
  '4 Hour': 240,
  '8 Hours': 480,
  '24 Hours': 1440,
  '72 Hours': 4320,
  '1 Week': 10080,
  Indefinitely: 525600,
};

const CONFIGURATION_PAGES = [
  { label: 'Plant', path: '/PlantConfig', icon: PlantConfigIcon },
  {
    label: 'Data Input',
    path: '/DataInputConfigv2',
    icon: DataInputConfigIcon,
  },
  {
    label: 'Escalation',
    path: '/escalation',
    icon: EscalationConfigIcon,
  },
  {
    label: 'Fixed Tasks',
    path: '/fixedTasksConfig',
    icon: FixedTasksConfigIcon,
  },
  {
    label: 'Alert Tasks',
    path: '/alertTasksConfig',
    icon: AlertConfigIcon,
  },
  {
    label: 'Manual Alert',
    path: '/manualAlertsConfig',
    icon: ManualAlertConfigIcon,
  },
  {
    label: 'Formula',
    path: '/formulaConfiguration',
    icon: FormulaConfigIcon,
  },
  {
    label: 'Dosing',
    path: '/DosingConfiguration',
    icon: DosingConfigIcon,
  },

  {
    label: 'One Time Task',
    path: '/oneTimeTask',
    icon: OneTimeTasksConfigIcon,
  },
];

const ESCALATION = {
  'Level 1': {
    time: '10:00:00',
    group: 'Escalation Level 1',
  },
  'Level 2': {
    time: '12:00:00',
    group: 'Escalation Level 2',
  },
  'Level 3': {
    time: '14:00:00',
    group: 'Escalation Level 3',
  },
};

const TYPE_OPTIONS = ['boolean', 'integer', 'dropDown', 'number'];

const INVENTORY_CATEGORIES_WITH_UNITS = ['chemicals'];
let frontendUrl = import.meta.env.VITE_APP_FEURL;
const baseURL = frontendUrl + '/plant-layout-diagrams';

const PLANT_LAYOUT_DIGITAL_ASSETS: { [key: string]: any } = {
  Tanks: {
    'Close Tank': {
      imageURL: baseURL + '/Tanks/tank-close.svg',
      imageControlId: 'new-tank-close',
    },
    'Open Tank': {
      imageURL: baseURL + '/Tanks/tank-open.svg',
      imageControlId: 'new-tank-open',
    },
    'Close Clarifier': {
      imageURL: baseURL + '/Tanks/clarifier-close.svg',
      imageControlId: 'clarifier-close',
    },
    'Open Clarifier': {
      imageURL: baseURL + '/Tanks/clarifier-open.svg',
      imageControlId: 'clarifier-open',
    },
    'Closed Dosing Tank': {
      imageURL: baseURL + '/Tanks/dosing-tank-close.svg',
      imageControlId: 'dosing-close',
    },
    'Open Dosing Tank': {
      imageURL: baseURL + '/Tanks/dosing-tank-open.svg',
      imageControlId: 'dosing-open',
    },
    Borewell: {
      imageURL: baseURL + '/Tanks/Borewell.svg',
      imageControlId: 'borewell',
    },
    'Electro Chlorinato': {
      imageURL: baseURL + '/Tanks/electro-chlorinato.svg',
      imageControlId: 'electro-chlorinato',
    },
    'Overheat Tank': {
      imageURL: baseURL + '/Tanks/overheat-tank.svg',
      imageControlId: 'overheat-tank',
    },
  },
  Pumps: {
    'Screw Pump': {
      imageURL: baseURL + '/Pumps/screwPump.svg',
      imageControlId: 'screwpump',
    },
    Submersible: {
      imageURL: baseURL + '/Pumps/submersible-pump.svg',
      imageControlId: 'submersible',
    },
    'Centrifugal Horizontal Pump': {
      imageURL: baseURL + '/Pumps/centrifugal-horizontal-pump.svg',
      imageControlId: 'centrifugal-horizontal-pump',
    },
    'Centrifugal Horizontal Pump Negative Suction': {
      imageURL: baseURL + '/Pumps/centrifugal-horizontal-pump-negative-suction.svg',
      imageControlId: 'centrifugal-horizontal-pump-negative-suction',
    },
    'Centrifugal Vertical Pump Negative Suction': {
      imageURL: baseURL + '/Pumps/centrifugal-vertical-pump-negative-suction.svg',
      imageControlId: 'centrifugal-vertical-pump-negative-suction',
    },
    'Dosing Pump': {
      imageURL: baseURL + '/Pumps/dosingPump.svg',
      imageControlId: 'dosing-pump',
    },
  },
  'Core Equipments': {
    ACF: {
      imageURL: baseURL + '/core-equipments/ACF.svg',
      imageControlId: 'acf',
    },
    Agitator: {
      imageURL: baseURL + '/core-equipments/Agitator.svg',
      imageControlId: 'agitator',
    },
    'Bar Screen Manual': {
      imageURL: baseURL + '/core-equipments/bar-screen-manual.svg',
      imageControlId: 'Bar Screen Manual',
    },
    'Filter Press': {
      imageURL: baseURL + '/core-equipments/filter-press.svg',
      imageControlId: 'filter-press-light-color',
    },
    MGF: {
      imageURL: baseURL + '/core-equipments/MGF.svg',
      imageControlId: 'mgf',
    },
    Ultrafilteration: {
      imageURL: baseURL + '/core-equipments/Ultrafilteration.svg',
      imageControlId: 'ultrafilteration',
    },
    UV: {
      imageURL: baseURL + '/core-equipments/uv.svg',
      imageControlId: 'uv',
    },
    Softner: {
      imageURL: baseURL + '/core-equipments/softner.svg',
      imageControlId: '', // no image control id
    },
    'Thermo Compresser': {
      imageURL: baseURL + '/core-equipments/thermo-compressor.svg',
      imageControlId: '', //no image control id
    },
    'Anaerobic Mixer': {
      imageURL: baseURL + '/core-equipments/anaerobic-mixer.svg',
      imageControlId: 'anaerobic-mixer',
    },
    'Anoxic Mixer': {
      imageURL: baseURL + '/core-equipments/anoxic-mixer.svg',
      imageControlId: 'anoxic-mixer-problem',
    },
    'Condensate Pot': {
      imageURL: baseURL + '/core-equipments/condensate-pot.svg',
      imageControlId: 'condensate-pot',
    },
    Decanter: {
      imageURL: baseURL + '/core-equipments/Decanter.svg',
      imageControlId: 'decanter',
    },
    'Forced Circulation Evaporator': {
      imageURL: baseURL + '/core-equipments/forced-circulation-evaporator.svg',
      imageControlId: 'forced-circulation-evaporator',
    },
    'Hydro Cyclone': {
      imageURL: baseURL + '/core-equipments/hydro-cyclone.svg',
      imageControlId: 'hydro-cyclone',
    },
    MCF: {
      imageURL: baseURL + '/core-equipments/MCF.svg',
      imageControlId: 'mcf',
    },
    NRV: {
      imageURL: baseURL + '/core-equipments/NRV.svg',
      imageControlId: 'nrv',
    },
    'Pre Header': {
      imageURL: baseURL + '/core-equipments/pre-heater.svg',
      imageControlId: 'pre-heater',
    },
    'RO Membrane': {
      imageURL: baseURL + '/core-equipments/ro-membrane.svg',
      imageControlId: 'ro-membrane',
    },
    'RO Catridge': {
      imageURL: baseURL + '/core-equipments/ro-catridge.svg',
      imageControlId: 'ro-catridge',
    },
    'Screw Press': {
      imageURL: baseURL + '/core-equipments/screw-press.svg',
      imageControlId: 'screw-press',
    },
    'Sludge Thickner': {
      imageURL: baseURL + '/core-equipments/sludge-thickner.svg',
      imageControlId: 'sludge-thickner',
    },
    'Vapour Liquid Seprator': {
      imageURL: baseURL + '/core-equipments/vapour-liquid-seprator.svg',
      imageControlId: 'liquidvapourseprator',
    },
    Ozonator: {
      imageURL: baseURL + '/core-equipments/Ozonator.svg',
      imageControlId: 'ozonator',
    },
    Compressor: {
      imageURL: baseURL + '/core-equipments/Compressor.svg',
      imageControlId: 'compressor',
    },
    'Primary Clarifier': {
      imageURL: baseURL + '/core-equipments/primary-clarifier.svg',
      imageControlId: 'clarifier',
    },
    'Air Compressor': {
      imageURL: baseURL + '/core-equipments/air-compressor.svg',
      imageControlId: 'air-compressor',
    },
  },
  Valves: {
    Valve: {
      imageURL: baseURL + '/Valves/valve.svg',
      imageControlId: '', //no image control id
    },
    '2 Way Manual Valve': {
      imageURL: baseURL + '/Valves/2-way-manual-valve.svg',
      imageControlId: 'svg-2-way-manual-valve',
    },
    '3 Way Manual Valve': {
      imageURL: baseURL + '/Valves/3-way-manual-valve.svg',
      imageControlId: 'svg-3-way-manual-valve',
    },
    'Solenoid 2 Way Valve': {
      imageURL: baseURL + '/Valves/solenoid-2-way-valve.svg',
      imageControlId: 'solenoid-2-way-valve',
    },
    'Solenoid 3 Way Valve': {
      imageURL: baseURL + '/Valves/solenoid-3-way-valve.svg',
      imageControlId: 'solenoid-3-way-valve',
    },
    'Pneumatic 2 Way Valve': {
      imageURL: baseURL + '/Valves/pneumatic-2-way-valve.svg',
      imageControlId: '', //no image control id
    },
    'Pneumatic 3 Way Valve': {
      imageURL: baseURL + '/Valves/pneumatic-3-way-valve.svg',
      imageControlId: '', //no image control id
    },
  },
  Headers: {
    'Air Blower Header': {
      imageURL: baseURL + '/Headers/header.svg',
      imageControlId: '', //no image control id
    },
  },
  Blowers: {
    'Air Blower': {
      imageURL: baseURL + '/Blowers/air-blower.svg',
      imageControlId: 'airblower',
    },
  },
  'Other Sensors': {
    'Flooding Sensor': {
      imageURL: baseURL + '/Sensors/flooding-sensor.svg',
      imageControlId: '', //no image control id
    },
    'Energy Meter': {
      imageURL: baseURL + '/Sensors/energy-meter.svg',
      imageControlId: '', //no image control id
    },
  },
  Flowmeter: {
    Flowmeter: {
      imageURL: baseURL + '/Flowmeter/flowmeter.svg',
      imageControlId: '', //no image control id
    },
  },
};

const PLANT_LAYOUT_DIGITAL_ASSETS_2: any[] = [
  {
    name: 'STP',
    imageURL: 'STP',
    imageControlId: '123',
  },
  {
    name: 'WTP',
    imageURL: 'WTP',
    imageControlId: '123',
  },
  {
    name: 'ETP',
    imageURL: 'ETP',
    imageControlId: '123',
  },
];

const GRANULARITY = [
  {
    label: 'Minutes',
    value: 'minutes',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
  { label: 'Weeks', value: 'weeks' },
  {
    label: 'Months',
    value: 'months',
  },
];

const TIME_RANGE = [
  {
    label: 'Today',
    value: 0,
    unit: 'minutes',
    getDateRange: (unitMultiplier: number) => {
      const today = dayjs();
      return [today.subtract(unitMultiplier, 'minute').startOf('minute'), today];
    },
  },
  {
    label: 'Today',
    value: 0,
    unit: 'hours',
    getDateRange: (unitMultiplier: number) => {
      const today = dayjs();
      return [today.subtract(unitMultiplier, 'hour').startOf('hour'), today];
    },
  },
  {
    label: 'Today',
    value: 0,
    unit: 'days',
    getDateRange: () => {
      const today = dayjs();
      return [today.startOf('day'), today];
    },
  },
  {
    label: 'Last 24 Hours',
    value: 1,
    unit: 'days',
    getDateRange: () => {
      const now = dayjs();
      const yesterday = now.subtract(24, 'hours');
      return [yesterday, now];
    },
  },
  {
    label: 'Yesterday',
    value: -1,
    unit: 'days',
    getDateRange: () => {
      const yesterday = dayjs().subtract(1, 'day');
      return [yesterday.startOf('day'), yesterday.endOf('day')];
    },
  },
  {
    label: 'Last 3 Days',
    value: 3,
    unit: 'days',
    getDateRange: () => {
      const today = dayjs();
      const threeDaysAgo = today.subtract(3, 'day');
      return [threeDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 7 Days',
    value: 7,
    unit: 'days',
    getDateRange: () => {
      const today = dayjs();
      const sevenDaysAgo = today.subtract(7, 'day');
      return [sevenDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 10 Days',
    value: 10,
    unit: 'days',
    getDateRange: () => {
      const today = dayjs();
      const tenDaysAgo = today.subtract(10, 'day');
      return [tenDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 30 Days',
    value: 30,
    unit: 'days',
    getDateRange: () => {
      const today = dayjs();
      const thirtyDaysAgo = today.subtract(30, 'day');
      return [thirtyDaysAgo.startOf('day'), today];
    },
  },
  {
    label: 'Last 3 Months',
    value: 3,
    unit: 'months',
    getDateRange: () => {
      const today = dayjs();
      const threeMonthsAgo = today.subtract(3, 'month');
      return [threeMonthsAgo.startOf('month'), today];
    },
  },
  {
    label: 'Last 6 Months',
    value: 6,
    unit: 'months',
    getDateRange: () => {
      const today = dayjs();
      const sixMonthsAgo = today.subtract(6, 'month');
      return [sixMonthsAgo.startOf('month'), today];
    },
  },
  {
    label: 'Custom',
    value: 0,
    unit: 'custom',
  },
];

const OTP_RESEND_TIMER = 100;

const GRAPH_TYPE: any = {
  line: 'line',
  bar: 'bar',
  tableC: 'table',
  tableD: 'table',
  number: 'number',
  flocDetector: 'flocDetector',
};

const WIDGET_TYPE: any = {
  line: 'graph',
  bar: 'graph',
  tableC: 'tableC',
  tableD: 'tableD',
  number: 'number',
  NumberGauge: 'NumberGauge',
  number2: 'number2',
  SankeyWidget: 'SankeyWidget',
  flocDetector: 'flocDetector',
  ManualEntryWidget: 'ManualEntryWidget',
};

const TIME_RANGE_MAPPINGS: any = {
  Today: { timeRangeType: 'relative', unitMultiplier: 0, unit: 'days' },
  'Last 24 Hours': {
    timeRangeType: 'relative',
    unitMultiplier: 1,
    unit: 'days',
  },
  Yesterday: {
    timeRangeType: 'relative',
    unitMultiplier: -1,
    unit: 'days',
  },
  'Last 3 Days': {
    timeRangeType: 'relative',
    unitMultiplier: 3,
    unit: 'days',
  },
  'Last 7 Days': {
    timeRangeType: 'relative',
    unitMultiplier: 7,
    unit: 'days',
  },
  'Last 10 Days': {
    timeRangeType: 'relative',
    unitMultiplier: 10,
    unit: 'days',
  },
  'Last 30 Days': {
    timeRangeType: 'relative',
    unitMultiplier: 30,
    unit: 'days',
  },
  'Last 3 Months': {
    timeRangeType: 'relative',
    unitMultiplier: 3,
    unit: 'months',
  },
  'Last 6 Months': {
    timeRangeType: 'relative',
    unitMultiplier: 6,
    unit: 'months',
  },
};

const MAX_ALLOWED_SENSOR_VALUE = 10000000;
const MIN_ALLOWED_SENSOR_VALUE = -10000000;

export default TIME_RANGE;

export {
  SENSOR_CATEGORIES,
  ASSIGN_ROLE,
  ISSUE_TYPES,
  PRIORITY_TYPES,
  ALERT_TYPES,
  PLANT_CONFIG_DATA,
  DAYS,
  WEEKS,
  ESCALATION,
  TYPE_OPTIONS,
  RELATIVE_TIME_LIST,
  INVENTORY_CATEGORIES_WITH_UNITS,
  PLANT_LAYOUT_DIGITAL_ASSETS,
  PLANT_LAYOUT_DIGITAL_ASSETS_2,
  CONFIGURATION_PAGES,
  GRANULARITY,
  TIME_RANGE,
  OTP_RESEND_TIMER,
  GRAPH_TYPE,
  WIDGET_TYPE,
  TIME_RANGE_MAPPINGS,
  MAX_ALLOWED_SENSOR_VALUE,
  MIN_ALLOWED_SENSOR_VALUE,
};

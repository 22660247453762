import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  Box,
  SelectChangeEvent,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ConfigSelectPopupProps {
  open: boolean;
  handleClose: () => void;
  options: string[];
  category: string;
  returnValue: (category: string, value: string) => void;
}

const ConfigSelectPopup: React.FC<ConfigSelectPopupProps> = ({
  open,
  handleClose,
  options,
  category,
  returnValue,
}) => {
  const [optionSelected, setOptionSelected] = useState('');

  const handleValueChange = (event: SelectChangeEvent<string>) => {
    setOptionSelected(event.target.value as string);
  };

  const handleSubmit = () => {
    if (!optionSelected.trim()) {
      toast.error(`Please select a ${category}!`);
    } else {
      returnValue(category, optionSelected);
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            marginTop: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            top: '5vh',
            position: 'absolute',
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h4">Select {category} Type</Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
            <InputLabel>Select {category}</InputLabel>
            <Select
              value={optionSelected}
              onChange={handleValueChange}
              label={`Select ${category}`}
            >
              {options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default ConfigSelectPopup;

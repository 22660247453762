import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LayoutComponent from '../LayoutComponent';
import FilterSection from './FilterSection';
import HeaderTitle from './HeaderTitle';
import LineGraph from './LineGrapgh';
import ActionButtonsPanel from './ActionsButtonsPanel';
import SaveCancelButtons from './SaveCancelButtons';
import { Box, Grid } from '@mui/material';
import {
  fetchLineGraphData,
  saveDataCorrection,
} from '../../services/DataCorrection/DataCorrection';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import DataCorrectionStyle from './Styles/DataCorrectionPageStyle';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface ChangedPoint {
  _id: string;
  value: number;
}

const DataCorrection: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { widgetStructure } = location.state || {};
  const [widgetPayloadData, setWidgetPayloadData] = useState(widgetStructure || null);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const [graphData, setGraphData] = useState<any>(null);
  const [selectedPointIndex, setSelectedPointIndex] = useState<number | null>(null);
  const [incrementValue, setIncrementValue] = useState<number | null>(null);
  const [addRoadValue, setAddRoadValue] = useState<number | null>(null);
  const [setDataPointValue, setSetDataPointValue] = useState<number | null>(null);
  const [deletedDataPoints, setDeletedDataPoints] = useState<any>({});
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);
  const [initialData, setInitialData] = useState();
  const [deletedPoints, setDeletedPoints] = useState<string[]>([]); // State for deleted IDs
  const [changedPointsOnly, setChangedPointsOnly] = useState<ChangedPoint[]>([]);
  const [onRoadDataChange, setOnRoadDataChange] = useState([]);
  const [dataPayload, setDataPayload] = useState<any>(null);
  const [dragSelectionMode, setDragSelectionMode] = useState(false);
  const [loading, setLoading] = useState(false); // Track loading state
  const [selectedPoints, setSelectedPoints] = useState([]);

  const toggleDragSelectionMode = () => {
    setDragSelectionMode(prev => !prev);
  };
  useEffect(() => {
    if (widgetPayloadData) {
      const fetchData = async () => {
        try {
          setDataPayload({
            plantId: widgetPayloadData?.plantId,
            plantName: widgetPayloadData?.plantName,
            sensorTag: widgetPayloadData?.sensorTag,
            sensorName: widgetPayloadData?.sensorName,
            sensorId: widgetPayloadData?.sensorId,
            fromDate: widgetPayloadData?.timeFrame,
          });

          setWidgetPayloadData(null);
        } catch (error) {
          toast('Unable to set widget payload');
          throw error;
        }
      };
      fetchData();
    }
  }, [widgetPayloadData]);

  const handleReset = () => {
    setGraphData(initialData);
    setChangedPointsOnly([]);
    setDeletedPoints([]);
    setOnRoadDataChange([]);
  };

  // Handler to update deleted IDs
  const handleDeletedIdsChange = (deletedIds: any) => {
    setDeletedPoints(deletedIds); // Update the state with deleted IDs
  };

  // Handler to update changed points
  const handleChangedPointsOnly = (changedPoints: any) => {
    setChangedPointsOnly(changedPoints); // Update the state with changed points (_id and value)
  };

  const handleOnRoadDataChange = (RoadPoint: any) => {
    setOnRoadDataChange(RoadPoint);
  };

  const handleMoveSelectionClick = () => {
    setIsEditModeEnabled(prev => !prev);
  };

  const handleDataPointClick = (index: number) => {
    setSelectedPointIndex(index);
  };

  const onIncrementApplied = () => {
    setIncrementValue(null);
    setAddRoadValue(null);
    setSetDataPointValue(null);
  };

  const handleIncrementDataPoint = (value: number) => {
    setIncrementValue(value);
  };

  const handleAddRoad = (value: number) => {
    setAddRoadValue(value);
  };

  const handleAiSetDataPoint = (value: number) => {
    setSetDataPointValue(value);
  };

  const handleDeleteDataPoint = (index: number, isDeleteConfirm: boolean) => {
    if (isDeleteConfirm) {
      const selectedDataPoint = graphData.metricsData[index];
      setDeletedDataPoints((prev: any) => ({
        ...prev,
        [index]: selectedDataPoint,
      }));

      const updatedData = deepClone(graphData);
      updatedData.metricsData[index] = null;
      setGraphData(updatedData);
    }
    setIsDeleteConfirm(false);
  };

  const handleUpdatedWidgetData = (updatedData: any) => {
    setGraphData(updatedData);
  };

  const handleSelectionFieldClick = (mode: boolean) => {
    setSelectionMode(mode);
  };

  const handleSave = async () => {
    const uniqueChangedPoints = Array.from(
      changedPointsOnly?.reduce((map, obj) => map.set(obj._id, obj), new Map())?.values()
    );

    const filteredChangedPoints = uniqueChangedPoints?.filter(
      (point: any) => !deletedPoints?.includes(point?._id)
    );

    const payload = {
      plantId: graphData?.datasets?.plantId,
      sensorId: graphData?.datasets[0]?.id || '',
      comment: '',
      updatedPoints: filteredChangedPoints,
      deletedPoints: deletedPoints,
      sensorOffSetData: onRoadDataChange,
    };

    try {
      setLoading(true);
      await saveDataCorrection(payload);
      setChangedPointsOnly([]);
      setDeletedPoints([]);
      setOnRoadDataChange([]);
      setSelectedPoints([]);
      // await handleExecute(dataPayload);
      toast('Data saved successfully');
    } catch (error) {
      toast('Failed to save data');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      const landingPage = localStorage.getItem('landingPage');
      navigate(`${landingPage}`);
    }
  };

  const handleExecute = async (payload: any) => {
    try {
      setDataPayload(payload);
      const data = await fetchLineGraphData(payload);
      if (data) {
        const finalDataVal = data?.data;
        if (finalDataVal?.datasets) {
          finalDataVal.datasets.backgroundColor = 'rgba(255,255,255,1)';
          finalDataVal.datasets.borderColor = 'rgba(24, 54, 80, 1)';
          finalDataVal.datasets.plantId = payload.plantId;
          const dataLength = finalDataVal?.datasets.data?.length || 0;
          finalDataVal.datasets.pointBackgroundColor =
            Array(dataLength).fill('rgba(255, 255, 255, 1)');
          setInitialData(finalDataVal);
          setGraphData(finalDataVal);
        }
      }
    } catch (error) {
      toast('Unable to get Data for the Configuration');
      throw error;
    }
  };

  return (
    <LayoutComponent
      style={DataCorrectionStyle.LayoutStyle}
      factoryResetContainer={true}
      plantId=""
    >
      <HeaderTitle title="Data Correction" />
      <Box sx={DataCorrectionStyle.filterSection}>
        <FilterSection onExecute={handleExecute} dataPayload={dataPayload} />
      </Box>

      <Grid container spacing={2} sx={DataCorrectionStyle.mainGridContainer}>
        <Grid item xs={12}>
          <Box sx={DataCorrectionStyle.graphAndActionsContainer}>
            <LineGraph
              isEditModeEnabled={isEditModeEnabled}
              widgetData={graphData}
              onPointClick={handleDataPointClick}
              incrementValue={incrementValue}
              addRoadValue={addRoadValue}
              setDataPointValue={setDataPointValue}
              deletedDataPoints={deletedDataPoints}
              isDeleteConfirm={isDeleteConfirm}
              onIncrementApplied={onIncrementApplied}
              selectionMode={selectionMode}
              updatedGraphData={handleUpdatedWidgetData}
              onDeletedIdsChange={handleDeletedIdsChange}
              changedPointsOnly={handleChangedPointsOnly}
              onRoadDataChange={handleOnRoadDataChange}
              dragSelectionMode={dragSelectionMode}
              selectedPoints={selectedPoints}
              setSelectedPoints={setSelectedPoints}
            />
            <ActionButtonsPanel
              handleReset={handleReset}
              onMoveSelectionClick={handleMoveSelectionClick}
              isEditModeEnabled={isEditModeEnabled}
              selectedDataPoint={selectedPointIndex !== null}
              onIncrementDataPoint={handleIncrementDataPoint}
              onAddRoad={handleAddRoad}
              onAiSetDataPoint={handleAiSetDataPoint}
              onDeleteDataPoint={(index: number) => handleDeleteDataPoint(index, isDeleteConfirm)}
              setDeleteConfirm={setIsDeleteConfirm}
              onSelectionFieldClick={handleSelectionFieldClick}
              onToggleDragSelectionMode={toggleDragSelectionMode}
            />
          </Box>
        </Grid>
      </Grid>

      <Box sx={DataCorrectionStyle.saveCancelButtons}>
        <SaveCancelButtons
          containerStyle={DataCorrectionStyle.saveCancelContainer}
          cancelButtonStyle={DataCorrectionStyle.cancelButtonCustom}
          saveButtonStyle={DataCorrectionStyle.saveButtonCustom}
          onSave={handleSave} // Attach the save handler
          onCancel={handleCancel} // Attach the cancel handler
          loading={loading}
        />
      </Box>
    </LayoutComponent>
  );
};

export default DataCorrection;

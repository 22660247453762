import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  ThemeProvider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import LayoutComponent from '../components/LayoutComponent';
import ConfigSelectPopup from '../components/ConfigSelectPopup/ConfigSelectPopup';
import {
  otherSensorDataGenerator,
  otherSensorNickNameGenerator,
  SensorTagGenerator,
} from '../utils/SensorTagGenerator';
import { PLANT_CONFIG_DATA } from '../constants/constants';
import {
  fetchPlantConfig,
  getOtherPlantsForClient,
  getPostFixTagForPlant,
  savePlantConfig,
} from '../services/plantConfiguration';
import LoadingState from '../components/plantConfiguration/LoadingState';
import Tank from '../components/plantConfiguration/Tank';
import CoreEquipment from '../components/plantConfiguration/CoreEquipment';
import PumpConfig from '../components/plantConfiguration/PumpConfig';
import FlowMeterConfig from '../components/plantConfiguration/FlowMeterConfig';
import ValveConfig from '../components/plantConfiguration/ValveConfig';
import HeaderConfig from '../components/plantConfiguration/HeaderConfig';
import BlowerConfig from '../components/plantConfiguration/BlowerConfig';
import OtherSensorsConfig from '../components/plantConfiguration/OtherSensorsConfig';
import dpTheme from '../styles/theme/dpTheme';
import { PrimaryRoundedButton } from '../styles/global/components/dpButtons';
import NextIcon from '@mui/icons-material/ArrowRightAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import EquipmentOutput from '../components/plantConfiguration/EquipmentOutput';
import { toast } from 'react-toastify';
import BottomNavigationComponent from '../components/BottomNavigationComponent/BottomNavigationComponent';
import AddPlantPopup from '../components/PlantConfigPopups/AddPlantPopup';
import OutputPlantConfig from '../components/plantConfiguration/OutputPlantConfig';
import ClonePlantConfigPopup from '../components/PlantConfigPopups/AddCloneConfigPopup';
import { MAX_ALLOWED_SENSOR_VALUE, MIN_ALLOWED_SENSOR_VALUE } from '../constants/constants';
import { FONT_NAME } from '../core-components/theme/theme';

const PlantConfigurationStyle = {
  PageTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: `${FONT_NAME}`,
    fontSize: '2.5rem',
    margin: '2rem 0',
  },
  AccordianSummary: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.6rem',
  },
  PageSubtitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: `${FONT_NAME}`,
    fontSize: '1.5rem',
    margin: '2rem 0',
  },
  BoxStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
};

export default function PlantConfiguration() {
  type pageParams = {
    id: string;
  };

  const { id } = useParams<pageParams>();

  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [popUpOptions, setpopUpOptions] = useState([]);
  const [popUpCategory, setpopUpCategory] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [tankChoice, setTankChoice] = useState<string[]>([]);
  const [headerChoice, setHeaderChoice] = useState<string[]>([]);
  const [equipChoice, setEquipChoice] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [valveChoice, setValveChoice] = useState<string[]>([]);
  const [postFixTagForPlant, setPostFixTagForPlant] = useState<string>('');
  const [openAddPlantPopup, setOpenAddPlantPopup] = useState(false);
  const [subPlantsThatCanBeAdded, setSubPlantsThatCanBeAdded] = useState<any>([]);
  const [isInitialPlantConfig, setIsInitialPlantConfig] = useState(false);
  const [showClonePopup, setShowClonePopup] = useState(false);
  let flocDetectorCount = useRef(0);
  const [plantConfig, setplantConfig] = useState<any>({
    Tanks: [],
    'Core Equipments': [],
    Pumps: [],
    Flowmeter: [],
    Valves: [],
    Headers: [],
    Blowers: [],
    'Other Sensors': [],
  });
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(count + 1);
    setLoading(true);
    fetchPlantConfig(id).then(data => {
      setLoading(false);
      setplantConfig(data);
      TankOptions(data);
      CoreEquipmentOptions(data);
      HeaderOptions(data);
      ValveOptions(data);

      if (
        data['Tanks'].length === 0 &&
        data['Pumps'].length === 0 &&
        data['Blowers'].length === 0 &&
        data['Other Sensors'].length === 0 &&
        data['Headers'].length === 0 &&
        data['Core Equipments'].length === 0 &&
        data['Flowmeter'].length === 0 &&
        data['Valves'].length === 0
      ) {
        setIsInitialPlantConfig(true);
      } else {
        setIsInitialPlantConfig(false);
      }
    });
    getPostFixTagForPlant(id).then(data => {
      console.log('postFixTag', data);
      setPostFixTagForPlant(data.data);
    });
    getOtherPlantsForClient(id!).then(data => {
      console.log('subPlantsThatCanBeAdded', data);
      setSubPlantsThatCanBeAdded(data.data);
    });
  }, []);

  function setPlantConfigFromClone(plantConfig: any) {
    setplantConfig(plantConfig);
    setplantConfig(plantConfig);
    TankOptions(plantConfig);
    CoreEquipmentOptions(plantConfig);
    HeaderOptions(plantConfig);
    ValveOptions(plantConfig);
  }

  useEffect(() => {
    console.log('plantConfig update called', plantConfig);
  }, [plantConfig]);

  const getEquipmentCount = (
    equipmentType: string,
    equipmentCategory: string,
    plantConfig: any
  ) => {
    console.log('from counter');
    console.log(equipmentType, equipmentCategory);
    let count = 0;
    let countArr: number[] = [];

    if (
      equipmentType === 'Tanks' ||
      equipmentType === 'Core Equipments' ||
      equipmentType === 'Other Sensors' ||
      equipmentType === 'Valves'
    ) {
      console.log('inside with subcategory iteration');
      let returnedCountArr = iterateAndReturnCountArrWithSubCategories(
        plantConfig[equipmentType],
        equipmentCategory
      );
      countArr = [...countArr, ...returnedCountArr];
    } else {
      let returnedCountArr = iterateAndReturnCountArrWithoutSubCategories(
        plantConfig[equipmentType]
      );
      countArr = [...countArr, ...returnedCountArr];
    }
    countArr = [
      ...countArr,
      ...loopOverAndFindCountFromSubEquipment(equipmentType, equipmentCategory, plantConfig),
    ];
    if (countArr.length === 0) {
      countArr.push(0);
    }
    count = Math.max(...countArr);
    return count;
  };
  const iterateAndReturnCountArrWithSubCategories = (
    selectedEquipmentArr: any,
    category: string
  ) => {
    let countArray = [];
    if (selectedEquipmentArr && selectedEquipmentArr.length > 0)
      for (let index = 0; index < selectedEquipmentArr.length; index++) {
        if (selectedEquipmentArr[index]['name'] === category) {
          countArray.push(selectedEquipmentArr[index]['count']);
        }
      }
    return countArray;
  };

  const iterateAndReturnCountArrWithoutSubCategories = (selectedEquipmentArr: any) => {
    let countArray = [];
    if (selectedEquipmentArr && selectedEquipmentArr.length > 0)
      for (let index = 0; index < selectedEquipmentArr.length; index++) {
        countArray.push(selectedEquipmentArr[index]['count']);
      }
    return countArray;
  };

  const loopOverAndFindCountFromSubEquipment = (
    equipmentType: string,
    equipmentCategory: string,
    plantConfig: any
  ) => {
    console.log('inside sub-equipment loop over');
    console.log('equipmentType , equipmentCategory', equipmentType, equipmentCategory);
    let configuredEquipments = Object.keys(plantConfig);
    let countArr: number[] = [];
    for (let configuredEquipment of configuredEquipments) {
      if (Object.prototype.toString.call(plantConfig[configuredEquipment]) == '[object Array]') {
        console.log('array found for ', configuredEquipment);
        console.log(plantConfig[configuredEquipment]);
        for (const singleEquipment of plantConfig[configuredEquipment]) {
          if (singleEquipment['subEquipments']) {
            console.log('sub equipment found');
            let subEquipmentConfig = singleEquipment['subEquipments'];
            console.log(subEquipmentConfig);
            if (
              equipmentType === 'Tanks' ||
              equipmentType === 'Core Equipments' ||
              equipmentType === 'Other Sensors' ||
              equipmentType === 'Valves'
            ) {
              let returnedCountArr = iterateAndReturnCountArrWithSubCategories(
                subEquipmentConfig[equipmentType],
                equipmentCategory
              );
              countArr = [...countArr, ...returnedCountArr];
            } else {
              let returnedCountArr = iterateAndReturnCountArrWithoutSubCategories(
                subEquipmentConfig[equipmentType]
              );
              countArr = [...countArr, ...returnedCountArr];
            }
          }
        }
      }
    }
    return countArr;
  };

  function countFlocDetectors(data: any) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].name === 'Floc Detector') {
        flocDetectorCount.current = Math.max(
          flocDetectorCount.current,
          Number(data[i].id[data[i].id.length - 1])
        );
      }
    }
    return flocDetectorCount.current;
  }

  // for add equipment popup
  const returnValue = (category: string, option: any) => {
    let plantConfigData = { ...plantConfig };
    let count = 0;
    // let countArray = [];
    // for (let index = 0; index < plantConfigData[category]?.length; index++) {
    //   if (plantConfigData[category][index]["name"] === option) {
    //     countArray.push(plantConfigData[category][index]["count"]);
    //   }
    // }
    // if (countArray.length > 0) {
    //   count = Math.max(...countArray);
    // }
    count = getEquipmentCount(category, option, plantConfigData);
    if (category === 'Tanks') {
      plantConfigData[category].push({
        id: option + (count + 1),
        name: option,
        count: count + 1,
        sensors: [],
        area: 0,
        height: 0,
        'free board': 0,
        material: '',
        drain: '',
        overflow: '',
        bypass: '',
        nickName: '',
        displayNode: true,
      });
    } else if (category === 'Valves') {
      if (plantConfigData[category] == null) plantConfigData['Valves'] = []; ///  so basically if we add a new equipment category in code after a plant has already been configered it picks the values from the prexisting database of plantconfig which doesn not contain the new added section hence can result in a null value

      plantConfigData[category].push({
        id: option + (count + 1),
        name: option,
        count: count + 1,
        In: [],
        Out: [],
        sensors: [],
        nickName: '',
        displayNode: true,
      });
    } else if (category === 'Other Sensors') {
      let equipmentConf = {
        id: option + Number(countFlocDetectors(plantConfigData[category]) + 1),
        name: option,
        count: countFlocDetectors(plantConfigData[category]) + 1,
        sensors: [],
        nickName: '',
        displayNode: true,
      };
      let newEquipmentConf = otherSensorDataGenerator(equipmentConf, postFixTagForPlant);
      plantConfigData[category].push(newEquipmentConf);
    } else {
      plantConfigData[category].push({
        id: option + (count + 1),
        name: option,
        count: count + 1,
        sensors: [],
        nickName: '',
        displayNode: true,
      });
    }

    setplantConfig(plantConfigData);
    if (category === 'Tanks') {
      TankOptions(plantConfigData);
      ValveOptions(plantConfigData);
      CoreEquipmentOptions(plantConfigData);
    }
    if (category === 'Core Equipments') {
      CoreEquipmentOptions(plantConfigData);
    }
    setOpenPopup(false);
  };
  // input and output config of equipment
  const handleMultiSelect = (category: string, index: number, type: string, value: any) => {
    let plantConfigData = { ...plantConfig };
    plantConfigData[category][index][type] = value;
    plantConfigData[category][index]['sensors'].forEach((sensor: any, sensorIndex: number) => {
      let generatedSensorData: { sensorTag: string; sensorNickName: string } = SensorTagGenerator(
        category,
        plantConfigData[category][index],
        sensorIndex,
        sensor['sensorName'],
        postFixTagForPlant
      );
      sensor['dateCreated'] = new Date();
      sensor['sensorTag'] = generatedSensorData['sensorTag'];
      let followUpVal = sensor['sensorTag'].split('_').slice(1).join('_');
      if (sensor['sensorName'] === 'ON/OFF Status') {
        plantConfigData[category][index]['hmiButtons'] = [
          // {
          //   hmiTag: `SWE_BYP_${}`,
          // },
          // {
          //   hmiTag: `SWE_MCONOFF_${sensor["sensorTag"]
          //     .split("_")
          //     .slice(1)
          //     .join("_")}`,
          // },
          {
            hmiTag: `BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_MCONOFF_${followUpVal}`,
          },
          {
            hmiTag: `HMI_BYP_${followUpVal}`,
          },
          {
            hmiTag: `PLC_BYP_${followUpVal}`,
          },
        ];
      }
    });

    setplantConfig(plantConfigData);

    setRefresh(!refresh);
  };

  interface INicknameChange {
    category: string;
    index: number;
    value: any;
  }

  const handleNickNameChange = ({ category, index, value }: INicknameChange) => {
    console.log('nick name change');
    let plantConfigData = { ...plantConfig };
    plantConfigData[category][index]['nickName'] = value;
    plantConfigData[category][index]['sensors'].forEach((sensor: any, sensorIndex: number) => {
      let generatedSensorData: { sensorTag: string; sensorNickName: string } = SensorTagGenerator(
        category,
        plantConfigData[category][index],
        sensorIndex,
        sensor['sensorName'],
        postFixTagForPlant
      );

      sensor['sensorNickName'] = generatedSensorData['sensorNickName'];
    });
    setplantConfig(plantConfigData);
    setRefresh(!refresh);
  };

  const handleShowOnDiagramCheck = (category: string, index: number, value: any) => {
    let plantConfigData = { ...plantConfig };
    plantConfigData[category][index]['displayNode'] = value;
    setplantConfig(plantConfigData);
    setRefresh(!refresh);
  };

  const handleBiDirectionalCheck = (category: string, index: number, value: any) => {
    let plantConfigData = { ...plantConfig };
    plantConfigData[category][index]['bidDirectionalControl'] = value;
    setplantConfig(plantConfigData);
    setRefresh(!refresh);
  };

  const updateTagName = (category: string, index: number, value: string) => {
    let plantConfigData = { ...plantConfig };
    plantConfigData[category][index]['tagName'] = value;
    plantConfigData[category][index]['sensors'].forEach((sensor: any, sensorIndex: number) => {
      let generatedSensorData: { sensorTag: string; sensorNickName: string } = SensorTagGenerator(
        category,
        plantConfigData[category][index],
        sensorIndex,
        sensor['sensorName'],
        postFixTagForPlant
      );

      sensor['sensorTag'] = generatedSensorData['sensorTag'];
      let followUpVal = sensor['sensorTag'].split('_').slice(1).join('_');
      if (sensor['sensorName'] === 'ON/OFF Status') {
        plantConfigData[category][index]['hmiButtons'] = [
          {
            hmiTag: `BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_MCONOFF_${followUpVal}`,
          },
          {
            hmiTag: `HMI_BYP_${followUpVal}`,
          },
          {
            hmiTag: `PLC_BYP_${followUpVal}`,
          },
        ];
      }
    });
    setplantConfig(plantConfigData);
    setRefresh(!refresh);
  };

  function updateOtherSensorNickname(index: any, value: string): void {
    let plantConfigData = { ...plantConfig };
    let equipmentConfig = plantConfigData['Other Sensors'][index];

    equipmentConfig['nickName'] = value;

    plantConfigData['Other Sensors'][index]['sensors'].forEach(
      (sensor: any, sensorIndex: number) => {
        plantConfigData['Other Sensors'][index]['sensors'][sensorIndex]['sensorNickName'] =
          otherSensorNickNameGenerator(equipmentConfig, sensorIndex);
      }
    );

    setplantConfig(plantConfigData);
    setRefresh(!refresh);
  }

  function updateOtherSensorTag(
    index: any,
    value: string | number,
    type: string,
    sensorIndex: number,
    ref?: any
  ): void {
    let plantConfigData = { ...plantConfig };

    if (type === 'maxValue') {
      const minValue =
        plantConfigData['Other Sensors'][index]['sensors'][sensorIndex]['minValue'] ??
        MIN_ALLOWED_SENSOR_VALUE;
      const prevValue = plantConfigData['Other Sensors'][index]['sensors'][sensorIndex][type];
      console.log('here before - ', value, minValue, ref?.current, typeof value, typeof minValue);
      if (value < minValue) console.log('here before2');
      if (value < minValue && ref?.current) {
        console.log('here');
        ref.current.value = prevValue ?? minValue;
        plantConfigData['Other Sensors'][index]['sensors'][sensorIndex][type] =
          prevValue ?? minValue;
        toast('Maximum sensor value cannot be lower than minimum value');
      } else {
        plantConfigData['Other Sensors'][index]['sensors'][sensorIndex][type] = value;
      }
    } else if (type === 'minValue') {
      const maxValue =
        plantConfigData['Other Sensors'][index]['sensors'][sensorIndex][type] ??
        MAX_ALLOWED_SENSOR_VALUE;
      const prevValue = plantConfigData['Other Sensors'][index]['sensors'][sensorIndex][type];
      if (value > maxValue && ref?.current) {
        ref.current.value = prevValue ?? maxValue;
        plantConfigData['Other Sensors'][index]['sensors'][sensorIndex][type] =
          prevValue ?? maxValue;
        toast('Minimum sensor value cannot be higher than the maxValue ');
      } else {
        plantConfigData['Other Sensors'][index]['sensors'][sensorIndex][type] = value;
      }
    } else {
      plantConfigData['Other Sensors'][index]['sensors'][sensorIndex][type] = value;
    }

    setplantConfig(plantConfigData);
    setRefresh(!refresh);
  }

  const addPump = () => {
    let plantConfigData = { ...plantConfig };
    let count = getEquipmentCount('Pumps', '', plantConfigData);
    plantConfigData['Pumps'].push({
      count: count + 1,
      sensors: [],
      In: [],
      Out: [],
      type: '',
      make: '',
      model: '',
      motorRating: '',
      nickName: '',
      id: 'Pump' + (count + 1),
      displayNode: true,
    });
    setplantConfig(plantConfigData);
    ValveOptions(plantConfigData);
    setRefresh(!refresh);
  };

  const addFlowMeter = () => {
    let plantConfigData = { ...plantConfig };
    let count = getEquipmentCount('Flowmeter', '', plantConfigData);
    plantConfigData['Flowmeter'].push({
      id: 'Flowmeter' + (count + 1),
      count: count + 1,
      In: [],
      Out: [],
      sensors: [{ sensorName: 'Flow Status' }, { sensorName: 'Totalizer' }],
      nickName: '',
      displayNode: true,
    });
    setplantConfig(plantConfigData);
    setRefresh(!refresh);
  };

  const addBlower = () => {
    let plantConfigData = { ...plantConfig };
    let count = getEquipmentCount('Blowers', '', plantConfigData);
    plantConfigData['Blowers'].push({
      id: 'Blower' + (count + 1),
      count: count + 1,
      sensors: [],
      Out: [],
      make: '',
      model: '',
      nickName: '',
      displayNode: true,
    });
    setplantConfig(plantConfigData);
    setRefresh(!refresh);
  };

  const addHeaders = () => {
    let plantConfigData = { ...plantConfig };
    let count = getEquipmentCount('Headers', '', plantConfigData);
    plantConfigData['Headers'].push({
      id: 'Header' + (count + 1),
      count: count + 1,
      sensors: [],
      Out: [],
      make: '',
      model: '',
      displayNode: true,
    });
    setplantConfig(plantConfigData);
    HeaderOptions(plantConfigData);
    setRefresh(!refresh);
  };

  const AddTankConfig = () => {
    setpopUpCategory('Tanks');
    //@ts-ignore
    setpopUpOptions(Object.keys(PLANT_CONFIG_DATA['Tanks']));
    setOpenPopup(true);
  };

  const AddCoreConfig = () => {
    setpopUpCategory('Core Equipments');
    //@ts-ignore
    setpopUpOptions(Object.keys(PLANT_CONFIG_DATA['Core Equipments']));
    setOpenPopup(true);
  };

  const AddOtherSensorConfig = () => {
    setpopUpCategory('Other Sensors');
    setpopUpOptions(PLANT_CONFIG_DATA['Other Sensors']);
    setOpenPopup(true);
  };
  const AddPlant = () => {
    setOpenAddPlantPopup(true);
  };
  const AddValveConfig = () => {
    setpopUpCategory('Valves');
    //@ts-ignore
    setpopUpOptions(Object.keys(PLANT_CONFIG_DATA['Valves']));
    setOpenPopup(true);
  };
  // delete equipment
  const RemoveElement = (category: string, index: number) => {
    let PLANT_CONFIG_DATA = { ...plantConfig };
    let categoryData = PLANT_CONFIG_DATA[category];
    categoryData.splice(index, 1);
    PLANT_CONFIG_DATA[category] = categoryData;
    toast.success('Equipment removed successfully');
    setplantConfig(PLANT_CONFIG_DATA);
    setRefresh(!refresh);
  };

  interface IUpdateValue {
    category: string;
    index: number;
    valuefor: string;
    value: any;
  }

  //update numeric values
  const updateValue = ({ category, index, valuefor, value }: IUpdateValue) => {
    let PLANT_CONFIG_DATA = { ...plantConfig };
    PLANT_CONFIG_DATA[category][index][valuefor] = value;
    setplantConfig(PLANT_CONFIG_DATA);
    setRefresh(!refresh);
  };

  const updateFlowmeterType = (category: string, index: number, valuefor: string, value: any) => {
    let PLANT_CONFIG_DATA = { ...plantConfig };
    PLANT_CONFIG_DATA[category][index][valuefor] = value;

    PLANT_CONFIG_DATA[category][index]['sensors'].forEach((sensor: any, sensorIndex: number) => {
      let generatedSensorData = SensorTagGenerator(
        category,
        PLANT_CONFIG_DATA[category][index],
        sensorIndex,
        sensor['sensorName'],
        postFixTagForPlant
      );
      sensor['dateCreated'] = new Date();
      sensor['sensorNickName'] = generatedSensorData['sensorNickName'];
      sensor['sensorTag'] = generatedSensorData['sensorTag'];
    });
    setplantConfig(PLANT_CONFIG_DATA);
    setRefresh(!refresh);
  };

  // logic needs rework for multiple sensors in 1 equipment change how it is processed when sensor is found
  function updateSensorList(category: string, index: number, sensor: string) {
    let PLANT_CONFIG_DATA = { ...plantConfig };
    let categoryData = PLANT_CONFIG_DATA[category];
    let sensorData = categoryData[index]['sensors'].find(
      (availableSensor: any) => availableSensor['sensorName'] === sensor
    );

    if (sensorData) {
      let indexOfSplice = categoryData[index]['sensors'].findIndex(
        (availableSensor: any) => availableSensor['sensorName'] === sensor
      );

      categoryData[index]['sensors'].splice(indexOfSplice, 1);
    } else {
      let indexOfSensor = categoryData[index]['sensors'].findIndex(
        (availableSensor: any) => availableSensor['sensorName'] === sensor
      );
      let generatedSensorData = SensorTagGenerator(
        category,
        categoryData[index],
        indexOfSensor,
        sensor,
        postFixTagForPlant
      );

      let newSensor = {
        sensorName: sensor,
        sensorTag: generatedSensorData['sensorTag'],
        sensorNickName: generatedSensorData['sensorNickName'],
        dateCreated: new Date(),
        display: true,
      };
      categoryData[index]['sensors'].push(newSensor);

      if (sensor === 'ON/OFF Status') {
        console.log(sensor, newSensor, 'newSensor');
        let followUpVal = newSensor.sensorTag.split('_').slice(1).join('_');
        categoryData[index]['hmiButtons'] = [
          {
            hmiTag: `BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_BYP_${followUpVal}`,
          },
          {
            hmiTag: `SWE_MCONOFF_${followUpVal}`,
          },
          {
            hmiTag: `HMI_BYP_${followUpVal}`,
          },
          {
            hmiTag: `PLC_BYP_${followUpVal}`,
          },
        ];
      }
    }
    PLANT_CONFIG_DATA[category] = categoryData;
    setplantConfig(PLANT_CONFIG_DATA);
    setRefresh(!refresh);
  }

  //update tag name for the sensor needs to be updated for disable on timestamp
  function updateSensorTagValue(
    category: string,
    index: number,
    type: string,
    sensorIndex: number,
    value: string,
    ref?: any
  ) {
    let PLANT_CONFIG_DATA = { ...plantConfig };
    let categoryData = PLANT_CONFIG_DATA[category];
    if (type === 'maxValue') {
      const minValue =
        categoryData[index]['sensors'][sensorIndex]['minValue'] ?? MIN_ALLOWED_SENSOR_VALUE;
      const prevValue = categoryData[index]['sensors'][sensorIndex][type];
      if (value < minValue && ref?.current) {
        ref.current.value = prevValue ?? minValue;
        categoryData[index]['sensors'][sensorIndex][type] = prevValue ?? minValue;
        toast('Maximum sensor value cannot be lower than minimum value');
      } else {
        categoryData[index]['sensors'][sensorIndex][type] = value;
      }
    } else if (type === 'minValue') {
      const maxValue =
        categoryData[index]['sensors'][sensorIndex]['maxValue'] ?? MAX_ALLOWED_SENSOR_VALUE;
      const prevValue = categoryData[index]['sensors'][sensorIndex][type];
      if (value > maxValue && ref?.current) {
        ref.current.value = prevValue ?? maxValue;
        categoryData[index]['sensors'][sensorIndex][type] = prevValue ?? maxValue;
        toast('Minimum sensor value cannot be higher than the maxValue ');
      } else {
        categoryData[index]['sensors'][sensorIndex][type] = value;
      }
    } else {
      categoryData[index]['sensors'][sensorIndex][type] = value;
    }
    if (
      categoryData[index]['sensors'][sensorIndex]['sensorName'] === 'ON/OFF Status' &&
      type === 'sensorTag'
    ) {
      let followUpVal = value.split('_').slice(1).join('_');
      categoryData[index]['hmiButtons'] = [
        {
          hmiTag: `BYP_${followUpVal}`,
        },
        {
          hmiTag: `SWE_BYP_${followUpVal}`,
        },
        {
          hmiTag: `SWE_MCONOFF_${followUpVal}`,
        },
        {
          hmiTag: `HMI_BYP_${followUpVal}`,
        },
        {
          hmiTag: `PLC_BYP_${followUpVal}`,
        },
      ];
    }
    PLANT_CONFIG_DATA[category] = categoryData;

    setplantConfig(PLANT_CONFIG_DATA);
    setRefresh(!refresh);
  }

  const loopOverAndFindListFromSubEquipment = async (equipmentType: string, plantConfig: any) => {
    let configuredEquipments = Object.keys(plantConfig);
    let equipmentArr: any[] = [];
    for (let configuredEquipment of configuredEquipments) {
      if (Object.prototype.toString.call(plantConfig[configuredEquipment]) == '[object Array]') {
        for (let equipment of plantConfig[configuredEquipment]) {
          if (equipment.hasOwnProperty('subEquipments')) {
            let subEquipmentConfig = equipment['subEquipments'];
            if (subEquipmentConfig[equipmentType]) {
              equipmentArr.push(...equipmentArr, ...subEquipmentConfig[equipmentType]);
            }
          }
        }
      }
    }
    return equipmentArr;
  };

  // sets list of available tanks in the plant config
  const TankOptions = async (plantConfigData: any) => {
    console.log('tag options called');
    let tankOptions = [];
    for (let i = 0; i < plantConfigData['Tanks'].length; i++) {
      tankOptions.push(
        plantConfigData['Tanks'][i]['name'] + ' ' + plantConfigData['Tanks'][i]['count']
      );
    }
    let subEquipmentConfiguredTanks = await loopOverAndFindListFromSubEquipment(
      'Tanks',
      plantConfigData
    );
    for (const subEquipmentConfiguredTank of subEquipmentConfiguredTanks) {
      tankOptions.push(
        subEquipmentConfiguredTank['name'] + ' ' + subEquipmentConfiguredTank['count']
      );
    }
    console.log('tankOptions', tankOptions);
    //@ts-ignore
    let fixedTankOptions = [...new Set(tankOptions)];
    setTankChoice(fixedTankOptions);
  };
  //adds core equipment to the tanks list for certain configurations like in flowmeters
  const CoreEquipmentOptions = async (plantConfigData: any) => {
    let EquipOptions = [];

    for (let i = 0; i < plantConfigData['Tanks'].length; i++) {
      EquipOptions.push(
        plantConfigData['Tanks'][i]['name'] + ' ' + plantConfigData['Tanks'][i]['count']
      );
    }

    let subEquipmentConfiguredTanks = await loopOverAndFindListFromSubEquipment(
      'Tanks',
      plantConfigData
    );
    for (const subEquipmentConfiguredTank of subEquipmentConfiguredTanks) {
      EquipOptions.push(
        subEquipmentConfiguredTank['name'] + ' ' + subEquipmentConfiguredTank['count']
      );
    }

    for (let i = 0; i < plantConfigData['Core Equipments'].length; i++) {
      EquipOptions.push(
        plantConfigData['Core Equipments'][i]['name'] +
          ' ' +
          plantConfigData['Core Equipments'][i]['count']
      );
      let subEquipmentConfiguredCoreEqts = await loopOverAndFindListFromSubEquipment(
        'Core Equipments',
        plantConfigData
      );
      for (const subEquipmentConfiguredCoreEqt of subEquipmentConfiguredCoreEqts) {
        EquipOptions.push(
          subEquipmentConfiguredCoreEqt['name'] + ' ' + subEquipmentConfiguredCoreEqt['count']
        );
      }
    }
    let fixedEquipmentOptions = [...new Set(EquipOptions)];
    setEquipChoice(fixedEquipmentOptions);
  };
  // sets list of available headers in the plant in order to add to the blowers
  const HeaderOptions = async (data: any) => {
    let headerOption = [];
    for (let i = 0; i < data['Headers'].length; i++) {
      headerOption.push('Header ' + data['Headers'][i]['count']);
    }
    let subEquipmentConfiguredHeaders = await loopOverAndFindListFromSubEquipment('Headers', data);
    for (const subEquipmentConfiguredHeader of subEquipmentConfiguredHeaders) {
      headerOption.push(
        subEquipmentConfiguredHeader['name'] + ' ' + subEquipmentConfiguredHeader['count']
      );
    }
    let fixedHeaderOptions = [...new Set(headerOption)];
    setHeaderChoice(fixedHeaderOptions);
  };
  //adds pumps to tank list to be selected for valve config
  const ValveOptions = async (plantConfigData: any) => {
    let valveOptions = [];
    for (let i = 0; i < plantConfigData['Tanks'].length; i++) {
      valveOptions.push(
        plantConfigData['Tanks'][i]['name'] + ' ' + plantConfigData['Tanks'][i]['count']
      );
    }
    let subEquipmentConfiguredTanks = await loopOverAndFindListFromSubEquipment(
      'Tanks',
      plantConfigData
    );
    for (const subEquipmentConfiguredTank of subEquipmentConfiguredTanks) {
      valveOptions.push(
        subEquipmentConfiguredTank['name'] + ' ' + subEquipmentConfiguredTank['count']
      );
    }
    for (let i = 0; i < plantConfigData['Pumps'].length; i++) {
      valveOptions.push(plantConfigData['Pumps'][i]['id']);
    }
    let subEquipmentConfiguredPumps = await loopOverAndFindListFromSubEquipment(
      'Pumps',
      plantConfigData
    );
    for (const subEquipmentConfiguredPump of subEquipmentConfiguredPumps) {
      valveOptions.push(
        subEquipmentConfiguredPump['name'] + ' ' + subEquipmentConfiguredPump['count']
      );
    }

    let fixedValveOptions = [...new Set(valveOptions)];
    setValveChoice(fixedValveOptions);
    setRefresh(!refresh);
  };

  const [showEquipmentOutputDialog, setShowEquipmentOutputDialog] = useState<boolean>(false);
  const [processing, setProcessing] = useState(false);
  const [outputOptions, setOutputOptions] = useState([]);
  const [equipmentCategories, setEquipmentCategories] = useState([]);
  const [categoriesValue, setCategoriesValue] = useState({});

  const mapPlantEquipmentData = async () => {
    //Create output options array
    const outputOptions: any = [];
    //@ts-ignore
    Object.keys(plantConfig).forEach(equipmentCategory => {
      if (equipmentCategory === 'sensorsName') {
        return '';
      } else {
        const options = plantConfig[equipmentCategory].map((equipment: any) => {
          let showInSelection = false;
          if (equipment.hasOwnProperty('displayNode')) {
            showInSelection = equipment['displayNode'];
          }
          return {
            id: equipment['id'],
            showInSelection: showInSelection,
            isSubEquipment: false,
          };
        });
        outputOptions.push(...options);

        plantConfig[equipmentCategory].map((equipment: any) => {
          console.log('equipment', equipment);
          let parentEquipmentId = equipment['id'];
          if (equipment.hasOwnProperty('subEquipments')) {
            console.log('subEquipments found');
            let keysOfSubEquipments = Object.keys(equipment['subEquipments']);
            for (const subEquipmentKey of keysOfSubEquipments) {
              const subEquipment = equipment['subEquipments'][subEquipmentKey];
              const subEquipmentOptions = subEquipment.map((subEquipment: any) => {
                let showInSelectionSubEqt = false;
                if (subEquipment.hasOwnProperty('displayNode')) {
                  showInSelectionSubEqt = subEquipment['displayNode'];
                }
                return {
                  id: subEquipment['id'],
                  showInSelection: showInSelectionSubEqt,
                  isSubEquipment: true,
                  parentEquipmentId,
                };
              });
              outputOptions.push(...subEquipmentOptions);
            }
          }
        });
      }
    });
    console.log('outputOptions', outputOptions);
    setOutputOptions(outputOptions);

    //Set Equipments categories
    const categories = Object.keys(plantConfig).filter(category => category !== 'sensorsName');
    //@ts-ignore
    setEquipmentCategories(categories);
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent plantId={id}>
        <Box m={1} p={1}>
          <span style={PlantConfigurationStyle.PageTitle}>
            Plant Configuration
            <SettingsIcon style={{ fontSize: '4rem', paddingLeft: '1rem' }} />
          </span>
        </Box>
        {loading && <LoadingState />}
        {!loading && (
          <>
            {isInitialPlantConfig && (
              <Box>
                <Button
                  sx={{
                    marginLeft: 'auto',
                    display: 'flex',
                    marginBottom: '10px',
                  }}
                  onClick={() => {
                    setShowClonePopup(true);
                  }}
                >
                  Clone config from pre configured plant
                </Button>
              </Box>
            )}

            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={e => e.stopPropagation()}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Tanks</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Tank
                  tankData={plantConfig['Tanks']}
                  AddTankConfig={AddTankConfig}
                  RemoveElement={RemoveElement}
                  updateSensorList={updateSensorList}
                  updateValue={updateValue}
                  handleNickNameChange={handleNickNameChange}
                  updateSensorTagValue={updateSensorTagValue}
                  plantConfig={plantConfig}
                  setPlantConfig={setplantConfig}
                  TankOptions={TankOptions}
                  ValveOptions={ValveOptions}
                  CoreEquipmentOptions={CoreEquipmentOptions}
                  HeaderOptions={HeaderOptions}
                  tankChoice={tankChoice}
                  valveChoice={valveChoice}
                  equipChoice={equipChoice}
                  headerChoice={headerChoice}
                  handleShowOnDiagramCheck={handleShowOnDiagramCheck}
                  sensorPostFix={postFixTagForPlant}
                />
                <Box style={PlantConfigurationStyle.BoxStyle}>
                  <Button
                    variant="contained"
                    style={{ color: 'white', margin: '1rem' }}
                    onClick={() => {
                      AddTankConfig();
                    }}
                  >
                    <AddIcon />
                    Add Tank
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Core Equipments</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <CoreEquipment
                  coreEquipmentData={plantConfig['Core Equipments']}
                  AddCoreConfig={AddCoreConfig}
                  RemoveElement={RemoveElement}
                  updateSensorList={updateSensorList}
                  updateValue={updateValue}
                  updateSensorTagValue={updateSensorTagValue}
                  handleNickNameChange={handleNickNameChange}
                  plantConfig={plantConfig}
                  setPlantConfig={setplantConfig}
                  TankOptions={TankOptions}
                  ValveOptions={ValveOptions}
                  CoreEquipmentOptions={CoreEquipmentOptions}
                  HeaderOptions={HeaderOptions}
                  tankChoice={tankChoice}
                  valveChoice={valveChoice}
                  equipChoice={equipChoice}
                  headerChoice={headerChoice}
                  handleShowOnDiagramCheck={handleShowOnDiagramCheck}
                  sensorPostFix={postFixTagForPlant}
                  handleBiDirectionalCheck={handleBiDirectionalCheck}
                />
                <Box style={PlantConfigurationStyle.BoxStyle}>
                  <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={() => {
                      AddCoreConfig();
                    }}
                  >
                    <AddIcon />
                    Add Core Equipment
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Pumps</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <PumpConfig
                  pumpData={plantConfig['Pumps']}
                  addPump={addPump}
                  RemoveElement={RemoveElement}
                  updateSensorList={updateSensorList}
                  updateValue={updateValue}
                  handleNickNameChange={handleNickNameChange}
                  updateTagName={updateTagName}
                  handleMultiSelect={handleMultiSelect}
                  tankChoice={tankChoice}
                  updateSensorTagValue={updateSensorTagValue}
                  plantConfig={plantConfig}
                  setPlantConfig={setplantConfig}
                  TankOptions={TankOptions}
                  ValveOptions={ValveOptions}
                  CoreEquipmentOptions={CoreEquipmentOptions}
                  HeaderOptions={HeaderOptions}
                  valveChoice={valveChoice}
                  equipChoice={equipChoice}
                  headerChoice={headerChoice}
                  handleShowOnDiagramCheck={handleShowOnDiagramCheck}
                  sensorPostFix={postFixTagForPlant}
                  handleBiDirectionalCheck={handleBiDirectionalCheck}
                />
                <Box style={PlantConfigurationStyle.BoxStyle}>
                  <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={() => {
                      addPump();
                    }}
                  >
                    <AddIcon />
                    Add Pump
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Flow Meters</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <FlowMeterConfig
                  flowMeterData={plantConfig['Flowmeter']}
                  addFlowMeter={addFlowMeter}
                  RemoveElement={RemoveElement}
                  updateValue={updateValue}
                  updateSensorTagValue={updateSensorTagValue}
                  handleNickNameChange={handleNickNameChange}
                  handleMultiSelect={handleMultiSelect}
                  equipChoice={equipChoice}
                  updateFlowmeterType={updateFlowmeterType}
                  plantConfig={plantConfig}
                  setPlantConfig={setplantConfig}
                  TankOptions={TankOptions}
                  ValveOptions={ValveOptions}
                  CoreEquipmentOptions={CoreEquipmentOptions}
                  HeaderOptions={HeaderOptions}
                  tankChoice={tankChoice}
                  valveChoice={valveChoice}
                  headerChoice={headerChoice}
                  handleShowOnDiagramCheck={handleShowOnDiagramCheck}
                  sensorPostFix={postFixTagForPlant}
                />
                <Box style={PlantConfigurationStyle.BoxStyle}>
                  <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={() => {
                      addFlowMeter();
                    }}
                  >
                    <AddIcon />
                    Add Flow Meter
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Valves</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ValveConfig
                  valveData={plantConfig['Valves']}
                  AddValveConfig={AddValveConfig}
                  RemoveElement={RemoveElement}
                  updateValue={updateValue}
                  updateSensorList={updateSensorList}
                  updateSensorTagValue={updateSensorTagValue}
                  handleNickNameChange={handleNickNameChange}
                  handleMultiSelect={handleMultiSelect}
                  valveChoice={valveChoice}
                  plantConfig={plantConfig}
                  setPlantConfig={setplantConfig}
                  TankOptions={TankOptions}
                  ValveOptions={ValveOptions}
                  CoreEquipmentOptions={CoreEquipmentOptions}
                  HeaderOptions={HeaderOptions}
                  tankChoice={tankChoice}
                  equipChoice={equipChoice}
                  headerChoice={headerChoice}
                  handleShowOnDiagramCheck={handleShowOnDiagramCheck}
                  sensorPostFix={postFixTagForPlant}
                  handleBiDirectionalCheck={handleBiDirectionalCheck}
                />
                <Box style={PlantConfigurationStyle.BoxStyle}>
                  <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={() => {
                      AddValveConfig();
                    }}
                  >
                    <AddIcon />
                    Add Valve
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Headers</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <HeaderConfig
                  headerData={plantConfig['Headers']}
                  addHeaders={addHeaders}
                  RemoveElement={RemoveElement}
                  updateSensorList={updateSensorList}
                  updateValue={updateValue}
                  handleNickNameChange={handleNickNameChange}
                  updateSensorTagValue={updateSensorTagValue}
                  handleMultiSelect={handleMultiSelect}
                  tankChoice={tankChoice}
                  plantConfig={plantConfig}
                  setPlantConfig={setplantConfig}
                  TankOptions={TankOptions}
                  ValveOptions={ValveOptions}
                  CoreEquipmentOptions={CoreEquipmentOptions}
                  HeaderOptions={HeaderOptions}
                  handleShowOnDiagramCheck={handleShowOnDiagramCheck}
                  valveChoice={valveChoice}
                  equipChoice={equipChoice}
                  headerChoice={headerChoice}
                  sensorPostFix={postFixTagForPlant}
                />
                <Box style={PlantConfigurationStyle.BoxStyle}>
                  <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={() => {
                      addHeaders();
                    }}
                  >
                    <AddIcon />
                    Add Headers
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Blowers</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <BlowerConfig
                  blowerData={plantConfig['Blowers']}
                  addBlower={addBlower}
                  RemoveElement={RemoveElement}
                  updateValue={updateValue}
                  handleNickNameChange={handleNickNameChange}
                  headerChoice={headerChoice}
                  handleMultiSelect={handleMultiSelect}
                  updateSensorList={updateSensorList}
                  updateSensorTagValue={updateSensorTagValue}
                  plantConfig={plantConfig}
                  setPlantConfig={setplantConfig}
                  TankOptions={TankOptions}
                  ValveOptions={ValveOptions}
                  CoreEquipmentOptions={CoreEquipmentOptions}
                  HeaderOptions={HeaderOptions}
                  tankChoice={tankChoice}
                  valveChoice={valveChoice}
                  equipChoice={equipChoice}
                  handleShowOnDiagramCheck={handleShowOnDiagramCheck}
                  sensorPostFix={postFixTagForPlant}
                  handleBiDirectionalCheck={handleBiDirectionalCheck}
                />
                <Box style={PlantConfigurationStyle.BoxStyle}>
                  <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={() => {
                      addBlower();
                    }}
                  >
                    <AddIcon />
                    Add Blowers
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Other Sensors</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <OtherSensorsConfig
                  otherSensorData={plantConfig['Other Sensors']}
                  AddOtherSensorConfig={AddOtherSensorConfig}
                  RemoveElement={RemoveElement}
                  updateValue={updateValue}
                  updateOtherSensorTag={updateOtherSensorTag}
                  updateOtherSensorNickname={updateOtherSensorNickname}
                  countFlocDetectors={countFlocDetectors}
                  plantConfig={plantConfig}
                  setPlantConfig={setplantConfig}
                  TankOptions={TankOptions}
                  ValveOptions={ValveOptions}
                  CoreEquipmentOptions={CoreEquipmentOptions}
                  HeaderOptions={HeaderOptions}
                  tankChoice={tankChoice}
                  valveChoice={valveChoice}
                  equipChoice={equipChoice}
                  headerChoice={headerChoice}
                  handleShowOnDiagramCheck={handleShowOnDiagramCheck}
                  sensorPostFix={postFixTagForPlant}
                />
                <Box style={PlantConfigurationStyle.BoxStyle}>
                  <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={() => {
                      AddOtherSensorConfig();
                    }}
                  >
                    <AddIcon />
                    Add Other Sensors
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Box m={1} p={1}>
              <span style={PlantConfigurationStyle.PageSubtitle}>
                Plant Output Configuration
                <SettingsIcon style={{ fontSize: '1.5rem', paddingLeft: '1rem' }} />
              </span>
            </Box>
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div style={PlantConfigurationStyle.AccordianSummary}>
                  <Typography variant="h4">Plant output plants</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <OutputPlantConfig
                  outputPlants={plantConfig['outputPlants'] ? plantConfig['outputPlants'] : []}
                  plantConfig={plantConfig}
                  setPlantConfig={setplantConfig}
                  handleShowOnDiagramCheck={handleShowOnDiagramCheck}
                />
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ color: 'white' }}
                    onClick={() => {
                      AddPlant();
                    }}
                  >
                    <AddIcon />
                    Add Plant
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Box
              m={4}
              p={1}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <PrimaryRoundedButton
                disabled={processing}
                onClick={async () => {
                  setProcessing(true);
                  mapPlantEquipmentData();
                  toast('Add Output for equipments');
                  setProcessing(false);
                  setShowEquipmentOutputDialog(true);
                }}
              >
                Next
                <NextIcon style={{ justifySelf: 'flex-end' }} />
              </PrimaryRoundedButton>
            </Box>
            <Box
              m={5}
              p={2}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <BottomNavigationComponent plantId={id!} currentPage="Plant" />
            </Box>
            <AddPlantPopup
              open={openAddPlantPopup}
              plantConfig={plantConfig}
              setPlantConfig={setplantConfig}
              handleClose={() => {
                setOpenAddPlantPopup(false);
              }}
              close={setOpenAddPlantPopup}
              otherPlantOptions={subPlantsThatCanBeAdded}
            />
            {openPopup && (
              <ConfigSelectPopup
                open={openPopup}
                handleClose={() => setOpenPopup(false)}
                options={popUpOptions}
                category={popUpCategory}
                returnValue={returnValue}
              />
            )}
            {showEquipmentOutputDialog && (
              <EquipmentOutput
                showEquipmentOutputDialog={showEquipmentOutputDialog}
                setShowEquipmentOutputDialog={setShowEquipmentOutputDialog}
                processing={processing}
                setProcessing={setProcessing}
                plantConfig={plantConfig}
                setplantConfig={setplantConfig}
                outputOptions={outputOptions}
                equipmentCategories={equipmentCategories}
                setCategoriesValue={setCategoriesValue}
                categoriesValue={categoriesValue}
                onConfirmAction={() => {
                  // save data after adding output field to each equipment
                  savePlantConfig(id!, plantConfig).then(data => {
                    if (data.featureAuthorization) {
                      // navigate(`/PlantVisualisationConfig/${id}`);
                      navigate(`/layoutVisualisationConfig/${id}`);
                    } else {
                      setProcessing(false);
                      navigate(`/DataInputConfigv2/${id}`);
                    }
                  });
                }}
              />
            )}
            <ClonePlantConfigPopup
              open={showClonePopup}
              setOpen={setShowClonePopup}
              setPlantConfig={setPlantConfigFromClone}
              plantId={id!}
            />
          </>
        )}
      </LayoutComponent>
    </ThemeProvider>
  );
}

import {
  Box,
  Stack,
  Typography
} from '@mui/material';
import DialogBox from '../../../core-components/theme/components/DialogBox/DialogBox';
import TicketComponentManualEntryWidget from './TicketComponentManualEntryWidget';

export default function HistoryPopupBox({ open, handleClose, maxWidth = '90%', alert, ticketIds=[] }: any) {

  return (
    <DialogBox
      title={<Typography variant="h5">History</Typography>}
      closeIconSize={'small'}
      open={open}
      handleClose={handleClose}
      maxWidth={maxWidth}
    >
      <Stack direction="column" gap={2}>
        <Box sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 'bold', color: '#16324A', fontSize: '14px' }}
            >
              {alert.heading?.eng}
            </Typography>
            {/* <Typography variant="body2" color="textSecondary" sx={{ ml: 1, fontStyle: 'italic' }}>
              (Edited)
            </Typography> */}
          </Box>
          <Typography variant="body2" sx={{ mt: 1, fontSize: '14px', color: '#16324A' }}>
            {alert.description?.eng}
          </Typography>
        </Box>
        <Box style={{ width: '100%' }}>
          {ticketIds?.map((ticketId: any, index: number) => {
            return (
              // <TicketComponentv2
              //   ticketId={ticketId.toString()}
              //   ticketIndex={index}
              //   plantId={alert.plantId}
              // />
              <TicketComponentManualEntryWidget
                ticketId={ticketId.toString()}
                ticketIndex={index}
                plantId={alert.plantId}
              />
            );
          })}
        </Box>
      </Stack>
    </DialogBox>
  );
}

import React from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress

interface SaveCancelButtonsProps {
  onSave?: () => void;
  onCancel?: () => void;
  containerStyle?: object;
  cancelButtonStyle?: object;
  saveButtonStyle?: object;
  loading?: boolean;
}

const SaveCancelButtons: React.FC<SaveCancelButtonsProps> = ({
  onSave,
  onCancel,
  containerStyle = {},
  cancelButtonStyle = {},
  saveButtonStyle = {},
  loading = false, // Default to false if not passed
}) => {
  return (
    <FormControl component="fieldset" sx={{ width: '100%', marginTop: '20px', ...containerStyle }}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="flex-end"
        sx={{ width: '100%' }}
        gap={{ xs: 2, md: 3 }}
      >
        <Button
          variant="outlined"
          onClick={onCancel}
          sx={{
            textTransform: 'none',
            fontSize: '14px',
            height: '42px',
            color: '#A0A0A0',
            borderColor: '#D0D0D0',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#F5F5F5',
            },
            ...cancelButtonStyle,
          }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          onClick={onSave}
          color="secondary"
          sx={{
            textTransform: 'none',
            fontSize: '14px',
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center the content of the button
            ...saveButtonStyle,
          }}
          disabled={loading}
        >
          {loading ? (
            <>
              Saving
              <CircularProgress
                size={25}
                sx={{ marginLeft: '10px', marginRight: 1, color: 'white' }}
              />{' '}
            </>
          ) : (
            'SAVE'
          )}
        </Button>
      </Stack>
    </FormControl>
  );
};

export default SaveCancelButtons;

//@ts-nocheck
import {
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getDIForm } from '../../services/dIForm';
import { PrimaryRoundedButton } from '../../styles/global/components/dpButtons';
import { updateDIForm } from '../../services/dIForm';
import { inputField } from '../../styles/global/components/dpFields';
import { DIForm, DIFormObject, Question } from '../../Interfaces/DIForm';
import classes from './DIForm.module.css';
import { InfoBox } from '../../styles/global/components/TextInfoBoxes';
import { PERMISSION_TAGS } from '../../constants/permissions';
import usePermissionCheck from '../../utilities/customHooks/usePermissionCheck';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface InputTypeProps {
  category: string;
  index: number;
  field: Question;
  showTimeInput: boolean;
  handleValueChange: Function;
  handleTimeChange: Function;
}

function NumberTypeInput(props: InputTypeProps) {
  const { category, index, field, showTimeInput, handleValueChange, handleTimeChange } = props;
  const [value, setValue] = useState(field?.time || null);

  let minVal = field.min ?? 0;
  let maxVal = field.max ?? 10000;

  return (
    <>
      <div className={classes['vertical-center-container']}>
        <FormControl className={classes['vertical-center-container']} fullWidth>
          <TextField
            variant="standard"
            type="number"
            id={field.title}
            label={field.title}
            defaultValue={parseFloat(field.currentValue) ?? 0}
            inputProps={{ min: minVal, max: maxVal, step: 0.1 }}
            placeholder={'Previous Value was ' + field.previousValue}
            onChange={e => {
              handleValueChange(category, index, e.target.value);
            }}
            style={inputField}
          />
        </FormControl>
        {showTimeInput && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Date&Time picker"
              value={dayjs(value)}
              views={['year', 'month', 'day', 'hours']}
              onChange={(newValue: string) => {
                setValue(newValue);
                handleTimeChange(category, index, newValue);
              }}
              renderInput={params => <TextField variant="outlined" fullWidth {...params} />}
            />
          </LocalizationProvider>
        )}
      </div>
    </>
  );
}

function DropDownTypeInput(props: InputTypeProps) {
  const { category, index, field, handleValueChange } = props;

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel className={classes['input-label']} id={field.title}>
          {field.title}
        </InputLabel>
        <Select
          style={inputField}
          labelId={field.title + '-label'}
          id={field.title}
          defaultValue={field.currentValue ?? 'None'}
          onChange={e => {
            handleValueChange(category, index, e.target.value);
          }}
        >
          {field.options.map((option: string) => {
            return <MenuItem value={option}>{option}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </div>
  );
}

function BooleanTypeInput(props: InputTypeProps) {
  const { category, index, field, handleValueChange } = props;

  return (
    <div className={classes['form-container']}>
      <FormControl fullWidth>
        <InputLabel className={classes['input-label']} id={field.title}>
          {field.title}
        </InputLabel>
        <Select
          labelId={field.title + '-label'}
          id={field.title}
          defaultValue={field.currentValue}
          onChange={e => {
            handleValueChange(category, index, e.target.value);
          }}
        >
          <MenuItem value={true}>Yes</MenuItem>

          <MenuItem value={false}>No</MenuItem>
        </Select>
      </FormControl>
      <InputLabel className={classes['section-heading']}>
        Previous Value was <b>{`${field.previousValue}`}</b>
      </InputLabel>
    </div>
  );
}

interface IProps {
  formType: string;
  plantId: string;
  setLabTestDIForm?: any;
  setMaintenanceDIForm?: any;
  setStreamDIForm?: any;
}

export default function DataInputForm({
  formType,
  plantId,
  setLabTestDIForm,
  setMaintenanceDIForm,
  setStreamDIForm,
}: IProps) {
  const [formObject, setFormObject] = useState<DIFormObject | null>(null);
  const [form, setForm] = useState<DIForm | null>(null);
  const [displayTitle, setDisplayTitle] = useState<string | boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [quesCategories, setQuesCategories] = useState<string[]>([]);
  const [processingSubmittingRequest, setProcessingSubmitRequest] = useState<boolean>(false);
  const [isStreamDataConfigured, setIsStreamDataConfigured] = useState(true);
  const [calculatedAt, setCalculatedAt] = useState(null);
  useEffect(() => {
    setLoading(true);
    getDIForm(plantId, formType)
      .then((data: DIFormObject) => {
        if (data === null || (data && data.formConfiguration.isFormConfigured === false)) {
          setIsStreamDataConfigured(false);
        }
        if (data === null && formType === 'labTestDIForm') {
          setLabTestDIForm(false);
        }
        if (data === null && formType === 'maintenanceDIForm') {
          setMaintenanceDIForm(false);
        }
        if (
          data &&
          data.formConfiguration.isFormConfigured === false &&
          formType === 'streamDIForm'
        ) {
          setStreamDIForm(false);
        }
        setFormObject(data);
        setForm(data?.form);
        setDisplayTitle(
          data?.formConfiguration?.isFormConfigured ? (
            data?.form?.formName
          ) : (
            <InfoBox currentStatus="info">{`${formType} - please enquire your CSM to use this form`}</InfoBox>
          )
        );
        setQuesCategories(Object.keys(data?.form?.questionBank));
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setProcessingSubmitRequest(true);

    if (calculatedAt && new Date(calculatedAt).getTime() > Date.now()) {
      toast.error('Date and Time should be in the past');
      setProcessingSubmitRequest(false);
      return;
    }

    const body = {
      plantId: plantId,
      formType: formType,
      form: {
        ...form,
        calculatedAt: new Date(calculatedAt)?.getTime() ? new Date(calculatedAt) : new Date(),
      },
    };

    try {
      await updateDIForm(body);
      toast(`${form!.formName} updated Succesfully`);
    } catch (error) {
      toast.error(`Failed to update ${form!.formName}`);
    } finally {
      setProcessingSubmitRequest(false);
      setCalculatedAt(null);
    }
  };

  const handleValueChange = (category: string, index: number, value: any) => {
    let data = form;
    console.log('value : ', value);
    data!.questionBank[category].questions[index]['currentValue'] = value;
    setForm(data);
    // sectionData[field] = event.target.value;
    console.log('data.questionBank[category] : ', data!.questionBank[category]);
  };

  const handleTimeChange = (category: string, index: number, value: any) => {
    let data = form;
    console.log('inside handleTimeChange value : ', value);
    console.log(
      `data.questionBank[category]["questions"][index]["time"]`,
      data!.questionBank[category]['questions'][index]['time']
    );
    value.utcOffset('+05:30');
    data!.questionBank[category]['questions'][index]['time'] = value.format(
      'YYYY-MM-DD HH:mm:ss.000Z'
    );
    setForm(data);
    // sectionData[field] = event.target.value;
    console.log('data.questionBank[category] : ', data!.questionBank[category]);
  };

  const { hasPermission: canAccessBackDate, loading: loadingBackDate } = usePermissionCheck(
    PERMISSION_TAGS.DataInputBackdate_Usage_AE
  );

  return (
    <>
      {!loading && !isStreamDataConfigured ? null : (
        <Box m={1}>
          <Accordion elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {loading ? 'loading ...' : displayTitle}
            </AccordionSummary>
            {formObject?.formConfiguration?.isFormConfigured && (
              <AccordionDetails className={classes['vertical-center-container']}>
                <form onSubmit={handleSubmit} className={classes['wide-form']}>
                  {canAccessBackDate && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        value={calculatedAt}
                        onChange={newValue => setCalculatedAt(newValue)}
                        label="Pick Date And Time"
                      />
                    </LocalizationProvider>
                  )}
                  {quesCategories?.map((category: string) => {
                    return (
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {form?.questionBank?.[category]?.nickName
                            ? form?.questionBank?.[category]?.nickName
                            : category}
                        </AccordionSummary>
                        <AccordionDetails className={classes['vertical-layout']}>
                          {form?.questionBank?.[category]?.questions?.map(
                            (field: Question, index: number) => {
                              if (field.type === 'number' || field.type === 'integer')
                                return (
                                  <NumberTypeInput
                                    category={category}
                                    index={index}
                                    field={field}
                                    showTimeInput={form?.timeInputRequired}
                                    handleValueChange={handleValueChange}
                                    handleTimeChange={handleTimeChange}
                                  />
                                );
                              else if (field.type === 'dropDown')
                                return (
                                  <DropDownTypeInput
                                    category={category}
                                    index={index}
                                    field={field}
                                    showTimeInput={form?.timeInputRequired}
                                    handleValueChange={handleValueChange}
                                    handleTimeChange={handleTimeChange}
                                  />
                                );
                              else if (field.type === 'boolean')
                                return (
                                  <BooleanTypeInput
                                    category={category}
                                    index={index}
                                    field={field}
                                    showTimeInput={form?.timeInputRequired}
                                    handleValueChange={handleValueChange}
                                    handleTimeChange={handleTimeChange}
                                  />
                                );
                              else return <>{'something'}</>;
                            }
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                  <div className={classes['centered-element']}>
                    <PrimaryRoundedButton
                      type="submit"
                      id="submit_btn"
                      disabled={processingSubmittingRequest ? true : false}
                    >
                      {processingSubmittingRequest ? 'Submitting...' : 'Submit'}
                    </PrimaryRoundedButton>
                  </div>
                </form>
              </AccordionDetails>
            )}
          </Accordion>
        </Box>
      )}
      <ToastContainer />
    </>
  );
}

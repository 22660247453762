//@ts-nocheck
import { clamp } from 'lodash';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useEffect, useRef, useState } from 'react';
import { getAggregationTypeFromId } from '../../../utils/dashboard.utils';
import { deepClone } from '@mui/x-data-grid/utils/utils';

Chart.register(...registerables);

export default function LineGraphComponent(props: any) {
  const {
    data,
    viewEveryNthTick,
    isEditModeEnabled,
    onPointClick,
    incrementValue,
    addRoadValue,
    setDataPointValue,
    onIncrementApplied,
    isDeleteConfirm,
    UpdatedWidgetData,
    onDeletedIdsChange,
    changedPointsOnly,
    onRoadDataChange,
    dragSelectionMode,
    selectedPoint,
    setSelectedPoint,
  } = props;

  const chartRef = useRef(null);
  const [editablePoint, setEditablePoint] = useState<any>(null);
  // const [selectedPoint, setSelectedPoint] = useState<any[]>([]);
  const [tempChangedPoint, setTempChangedPoint] = useState(null);
  const [dragStart, setDragStart] = useState(null);
  const [dragEnd, setDragEnd] = useState(null);

  const handlePointClick = (event: any) => {
    const chart = chartRef.current;
    if (!chart) return;

    const points = chart?.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
    if (points.length) {
      const newSelectedPoints = points.map((point: any) => ({
        datasetIndex: point.datasetIndex,
        index: point.index,
        calculatedAtInMs: point.calculatedAtInMs,
      }));

      // Ensure prevSelectedPoints is an array before spreading
      setSelectedPoint(newSelectedPoints);

      if (isEditModeEnabled) {
        const editablePoints = newSelectedPoints.map((point: any) => ({
          datasetIndex: point.datasetIndex,
          index: point.index,
          originalValue: data?.datasets[point.datasetIndex]?.data[point.index],
        }));
        setEditablePoint(editablePoints);
      }

      if (onPointClick) {
        // Pass selected points to the callback
        onPointClick(newSelectedPoints.map((point: any) => point.index));
      }

      chartRef?.current?.update();
    }
  };

  const handleMouseMove = (event: any) => {
    if (!editablePoint || !chartRef.current || !isEditModeEnabled) return;

    const yScale = chartRef?.current?.scales['y'];
    const newYValue = yScale.getValueForPixel(event.nativeEvent.offsetY);

    const updatedData = deepClone(data);

    editablePoint.forEach((point: any) => {
      const { datasetIndex, index } = point;
      updatedData.datasets[datasetIndex].data[index] = newYValue;
    });

    UpdatedWidgetData(updatedData);

    editablePoint.forEach((point: any) => {
      const index = point.index;
      const _id = data?._ids[index];
      const calculatedAtInMs = data?.calculatedAtInMs[index];

      if (_id) {
        setTempChangedPoint((prevTempPoints: any) => {
          const updatedTempPoints = prevTempPoints ? [...prevTempPoints] : [];
          const existingIndex = updatedTempPoints.findIndex(
            (tempPoint: any) => tempPoint._id === _id
          );

          if (existingIndex !== -1) {
            updatedTempPoints[existingIndex] = {
              _id: _id,
              value: Number(newYValue),
              calculatedAtInMs: Number(calculatedAtInMs),
            };
          } else {
            updatedTempPoints.push({
              _id: _id,
              value: Number(newYValue),
              calculatedAtInMs: Number(calculatedAtInMs),
            });
          }

          return updatedTempPoints;
        });
      }
    });

    chartRef?.current?.update();
  };

  const handleMouseUp = () => {
    if (tempChangedPoint && tempChangedPoint.length > 0) {
      changedPointsOnly((prevPoints: any) => {
        const updatedPoints = [...prevPoints];
        tempChangedPoint.forEach((tempPoint: any) => {
          const existsIndex = updatedPoints.findIndex((point: any) => point._id === tempPoint._id);

          if (existsIndex === -1) {
            updatedPoints.push(tempPoint);
          } else {
            updatedPoints[existsIndex] = tempPoint;
          }
        });

        return updatedPoints;
      });

      setTempChangedPoint([]);
    }

    if (editablePoint) {
      setEditablePoint(null);
    }
  };

  useEffect(() => {
    if (incrementValue !== null && selectedPoint?.length > 0) {
      const updatedData = deepClone(data);

      // Iterate over all selected points
      selectedPoint.forEach((point: any) => {
        const { datasetIndex, index } = point;
        const updatedValue = (
          parseFloat(updatedData.datasets[datasetIndex].data[index]) + incrementValue
        ).toString();

        updatedData.datasets[datasetIndex].data[index] = updatedValue;

        // Update changed points data
        const _id = data?._ids[index];
        const calculatedAtInMs = data?.calculatedAtInMs[index];
        if (_id) {
          changedPointsOnly((prevPoints: any) => [
            ...prevPoints,
            { _id, value: Number(updatedValue), calculatedAtInMs: Number(calculatedAtInMs) },
          ]);
        }
      });

      // Update widget data after applying the increment to selected points
      UpdatedWidgetData(updatedData);

      // Reset incrementValue in parent component (if applicable)
      if (onIncrementApplied) {
        onIncrementApplied();
      }
    }
  }, [incrementValue, selectedPoint, onIncrementApplied, data]);

  // Apply "Add Road" increment to the selected point and all subsequent points
  useEffect(() => {
    if (addRoadValue !== null && selectedPoint?.length) {
      // Iterate over selected points
      const updatedData = deepClone(data);
      const newSensorOffSetData: object[] = []; // Array to hold new roadData objects

      selectedPoint.forEach(({ datasetIndex, index }) => {
        for (let i = index; i < updatedData.datasets[datasetIndex].data.length; i++) {
          const updatedValue = (
            parseFloat(updatedData.datasets[datasetIndex].data[i]) + addRoadValue
          ).toString();

          // Update graph data for visual representation
          updatedData.datasets[datasetIndex].data[i] = updatedValue;
        }
        const operationType = addRoadValue < 0 ? 'DECREMENT' : 'INCREMENT';
        const absoluteValue = Math.abs(addRoadValue);

        const sensorOffSetData = {
          value: absoluteValue, // Increment value
          calculatedAtInMs: updatedData.calculatedAtInMs[index], // Time reference
          operationType: operationType,
        };

        newSensorOffSetData.push(sensorOffSetData); // Add new roadData to the array
      });

      // Send the updated roadData array back via props
      if (onRoadDataChange) {
        onRoadDataChange((prevRoadData: any) => [...prevRoadData, ...newSensorOffSetData]);
      }

      // Reflect updated data on the graph
      UpdatedWidgetData(updatedData);

      if (onIncrementApplied) {
        onIncrementApplied(); // Reset addRoadValue in parent
      }
    }
  }, [addRoadValue, selectedPoint, onIncrementApplied, data, props]);

  // Apply a direct set value to the selected data point
  useEffect(() => {
    if (setDataPointValue !== null && selectedPoint?.length > 0) {
      const updatedData = deepClone(data);
      const updatedValue = setDataPointValue.toString();

      selectedPoint.forEach((point: any) => {
        const { datasetIndex, index } = point;
        updatedData.datasets[datasetIndex].data[index] = updatedValue;

        // Update changed points data
        const _id = data?._ids[index];
        const calculatedAtInMs = data?.calculatedAtInMs[index];
        if (_id) {
          changedPointsOnly((prevPoints: any) => [
            ...prevPoints,
            { _id, value: Number(updatedValue), calculatedAtInMs: Number(calculatedAtInMs) },
          ]);
        }
      });

      // Update widget data after setting the value for selected points
      UpdatedWidgetData(updatedData);

      // Reset setDataPointValue in parent component (if applicable)
      if (onIncrementApplied) {
        onIncrementApplied();
      }
    }
  }, [setDataPointValue, selectedPoint, onIncrementApplied, data]);

  // Handle delete confirm
  useEffect(() => {
    if (isDeleteConfirm && selectedPoint?.length > 0) {
      const updatedData = deepClone(data); // Clone data to avoid mutating the original object

      // Initialize an empty array to track deleted points if not already done
      const deletedPoints = onDeletedIdsChange?.((prevData: any) => prevData || []) || [];

      let cumulativeOffset = 0; // Tracks the index shift for consecutive deletions

      selectedPoint.forEach((point: any, i: number) => {
        const { datasetIndex, index } = point;

        // Adjust index based on previously deleted points and current loop deletions
        const adjustedIndex =
          index +
          deletedPoints.reduce((acc: number, deletedPoint: any) => {
            const isBefore = deletedPoint.calculatedAtInMs < updatedData.calculatedAtInMs[index];
            return isBefore ? acc - 1 : acc;
          }, 0) -
          cumulativeOffset; // Offset for consecutive deletions

        const _id = updatedData?._ids[adjustedIndex];
        const calculatedAtInMs = updatedData?.calculatedAtInMs[adjustedIndex];
        const deletedValue = updatedData.datasets[datasetIndex].data[adjustedIndex];

        if (_id) {
          onDeletedIdsChange((prevData: any) => {
            const updatedIds = [
              ...(prevData || []),
              { _id, calculatedAtInMs, dataBlock: deletedValue },
            ];
            if (onDeletedIdsChange) {
              onDeletedIdsChange(updatedIds);
            }
            return updatedIds;
          });
        }

        // Remove the data for each selected point
        updatedData.datasets[datasetIndex].data.splice(adjustedIndex, 1);
        updatedData.labels.splice(adjustedIndex, 1);
        updatedData.calculatedAtInMs.splice(adjustedIndex, 1);
        updatedData.labelsWithYear.splice(adjustedIndex, 1);
        updatedData._ids.splice(adjustedIndex, 1);

        cumulativeOffset++; // Increment the offset after each deletion
      });

      // Update widget data after all deletions
      UpdatedWidgetData(updatedData);

      setSelectedPoint([]); // Clear selected points after deletion
      onPointClick(null); // Reset point click state
    }
  }, [isDeleteConfirm, selectedPoint, data, onDeletedIdsChange]);

  // Handle mouse down to start drag selection
  const handleMouseDown_1 = (event: any) => {
    if (!dragSelectionMode) return; // Check if drag selection mode is active
    const chart = chartRef.current;
    if (!chart) return;

    const rect = chart.canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    setDragStart({ x, y });
    setDragEnd(null);
    setSelectedPoint([]); // Reset selected points
  };

  // Handle mouse move to update drag selection
  const handleMouseMove_1 = (event: any) => {
    if (!dragSelectionMode || !dragStart) return;

    const chart = chartRef.current;
    if (!chart) return;

    const rect = chart.canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    setDragEnd({ x, y });

    const ctx = chart.ctx;
    ctx.save(); // Save the current canvas state

    // Clear the canvas only if needed to avoid erasing chart elements
    // chart.clear(); // Avoid this line unless necessary, as we are only drawing the selection box

    // Redraw the existing chart
    chart.update();

    // Draw the selection rectangle
    ctx.globalAlpha = 0.8; // Set transparency for the fill
    ctx.fillStyle = 'rgba(0, 123, 255, 0.2)';
    ctx.strokeStyle = 'rgba(0, 123, 255, 0.5)';
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.rect(dragStart.x, dragStart.y, x - dragStart.x, y - dragStart.y);
    ctx.fill();
    ctx.stroke();

    ctx.restore(); // Restore the previous canvas state
  };

  // Handle mouse up to complete selection
  const handleMouseUp_1 = () => {
    if (!dragSelectionMode || !dragStart || !dragEnd) return;

    const chart = chartRef.current;
    if (!chart) return;

    const selectedPoints: any[] = [];
    const { datasets } = data;

    datasets.forEach((dataset: any, datasetIndex: any) => {
      dataset.data.forEach((value: any, index: any) => {
        const xValue = chart.scales.x.getPixelForValue(data.labels[index]);
        const yValue = chart.scales.y.getPixelForValue(value);

        if (
          xValue >= Math.min(dragStart.x, dragEnd.x) &&
          xValue <= Math.max(dragStart.x, dragEnd.x) &&
          yValue >= Math.min(dragStart.y, dragEnd.y) &&
          yValue <= Math.max(dragStart.y, dragEnd.y)
        ) {
          selectedPoints.push({ datasetIndex, index });
        }
      });
    });

    setSelectedPoint(selectedPoints);

    if (isEditModeEnabled) {
      const editablePoints = selectedPoints.map((point: any) => ({
        datasetIndex: point.datasetIndex,
        index: point.index,
        originalValue: data?.datasets[point.datasetIndex]?.data[point.index],
      }));

      setEditablePoint(editablePoints);
    }

    if (onPointClick) {
      onPointClick(selectedPoints.map((point: any) => point.index));
    }

    setDragStart(null);
    setDragEnd(null);
  };

  // Add event listeners for drag selection
  useEffect(() => {
    const chart = chartRef.current;
    if (!chart) return;

    const canvas = chart.canvas;

    canvas.addEventListener('mousedown', handleMouseDown_1);
    canvas.addEventListener('mousemove', handleMouseMove_1);
    canvas.addEventListener('mouseup', handleMouseUp_1);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown_1);
      canvas.removeEventListener('mousemove', handleMouseMove_1);
      canvas.removeEventListener('mouseup', handleMouseUp_1);
    };
  }, [dragSelectionMode, dragStart, dragEnd]);

  return (
    <div
      onClick={handlePointClick}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{ width: '100%', height: '100%' }}
    >
      <Line
        ref={chartRef}
        data={{
          ...deepClone(data),
          datasets: data?.datasets?.map((dataset: any, datasetIndex: any) => ({
            ...deepClone(dataset),
            pointRadius: (context: any) => {
              const isSelected = selectedPoint?.some(
                (point: any) =>
                  point.datasetIndex === datasetIndex &&
                  (point.index === context.dataIndex || point.dataIndex === context.dataIndex) // Adjusted to check both `index` and `dataIndex`
              );
              return isSelected ? 8 : 4;
            },
            pointBackgroundColor: (context: any) => {
              const isSelected = selectedPoint?.some(
                (point: any) =>
                  point.datasetIndex === datasetIndex &&
                  (point.index === context.dataIndex || point.dataIndex === context.dataIndex) // Adjusted to check both `index` and `dataIndex`
              );
              return isSelected ? 'black' : '#ffffff';
            },
          })),
        }}
        options={{
          plugins: {
            legend: {
              display: false,
              position: 'top',
              labels: {
                boxHeight: 3,
                boxWidth: 30,
                font: {
                  size: context => {
                    const height = context.chart.height;
                    const width = context.chart.width;
                    const size = Math.round(height / 27);
                    return clamp(11, size, 19);
                  },
                },
                color: '#666',
              },
            },
            tooltip: {
              enabled: true,
              callbacks: {
                title: context => {
                  if (context.length > 0) {
                    const index = context[0]?.dataIndex;
                    const data = context[0]?.chart?.data?.labels;
                    const currentTime = data[index] || '';
                    const datasetIndex = context[0]?.datasetIndex;
                    const dataset = context[0]?.chart?.data?.datasets[datasetIndex];
                    const nextTime =
                      index < data?.length - 1 ? data[index + 1] : dataset?.lastTimeLabel;

                    return getAggregationTypeFromId(dataset.id) !== 'Raw'
                      ? `${currentTime} ${nextTime?.length > 0 ? `-` : ''} ${nextTime}`
                      : `${currentTime}`;
                  }
                  return '';
                },
                label: context => {
                  const value = context?.parsed?.y;
                  return `Sensor Value: ${value}`;
                },
              },
            },
          },
          scales: {
            x: {
              grid: { display: true },
              ticks: {
                callback: function (val, index) {
                  return index % viewEveryNthTick === 0 ? this.getLabelForValue(val) : '';
                },
                color: '#666',
                font: {
                  size: function (context) {
                    const width = context?.chart?.width;
                    const height = context?.chart?.height;
                    const size = Math.round(height / 27);
                    return clamp(12, size, 16);
                  },
                },
              },
            },
            y: {
              grid: { display: true },
              ticks: {
                color: '#666',
                font: {
                  size: function (context) {
                    const width = context?.chart?.width;
                    const height = context?.chart?.height;
                    const size = Math.round(height / 27);
                    return clamp(12, size, 16);
                  },
                },
              },
            },
          },
          maintainAspectRatio: false,
        }}
        plugins={[]}
      />
    </div>
  );
}
